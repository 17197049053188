import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import {
  TotalRequirementFilters,
  TotalRequirementWithoutFilters,
} from "./Filters";
import { TotalRequirementChart } from "./TotalRequirementChart";
import { genericForwardMontsColumn } from "../../utils/genericForwardMontsColumn";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { getTotalList } from "../../api/totalR/Api.service";
import { getFilterData } from "../../api/steel/Api.service";
import { clearSelectedFilters } from "./slice/TotalRSlice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export const TotalRequirementScreen = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);

  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const dispatch: AppDispatch = useDispatch();
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [isTableView, setIsTableView] = useState(true);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [shortageData, setShortageData] = useState({
    name: "",
    grade: "",
    code: [],
    probability: "75 - 100",
    purchaseEndDate: "",
    processingEndDate: "",
    processingStartDate: "",
    purchaseStartDate: "",
    location: masterLocation.locationId,
  });

  useEffect(() => {
    refreshTotal();
  }, [pagination, shortageData]);

  const { totalRListData } = useSelector((state: any) => state.TotalRState);
  useEffect(() => {
    // dispatch(getFilterData({}));
    dispatch(getFilterData({ locationId: shortageData?.location }));
    dispatch(clearSelectedFilters());
  }, []);

  useEffect(() => {
    setShortageData({
      ...shortageData,
      location: masterLocation.locationId,
    });
  }, [masterLocation]);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };
  const refreshTotal = () => {
    const {
      name,
      grade,
      code,
      probability,
      purchaseEndDate,
      processingEndDate,
      processingStartDate,
      purchaseStartDate,
      location,
    } = shortageData;

    const searchData = {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      name,
      code,
      grade,
      probability,
      purchaseEndDate,
      processingEndDate,
      processingStartDate,
      purchaseStartDate,
      location,
    };
    if (
      Boolean(processingStartDate) !== Boolean(processingEndDate) ||
      Boolean(purchaseStartDate) !== Boolean(purchaseEndDate)
    )
      return;
    dispatch(getTotalList(searchData));
    dispatch(getFilterData({ locationId: shortageData?.location }));
  };

  const synopsisStyles = {
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100px",
    whiteSpace: "nowrap",
  };

  const renderClickablecell = (params: any) => {
    const row = params.row.original;
    const field =
      params.column.id.charAt(0).toUpperCase() + params.column.id.slice(1);
    const isClickEnable = row?.isClickEnable;
    const hrefLink = `/schedule`;

    return (
      <>
        {isClickEnable ? (
          field === "Code" ? (
            <span className="yellow">
              <b>{params?.cell?.getValue()?.toLocaleString()}</b>
            </span>
          ) : (
            <Link to={hrefLink} state={{ params: field }}>
              <span className="clickableText yellow">
                {params?.cell?.getValue()?.toLocaleString()}
              </span>
            </Link>
          )
        ) : field === "Name" ? (
          <span
            // @ts-ignore
            style={{
              ...synopsisStyles,
              maxWidth: "unset",
            }}
            title={row?.name}
          >
            {row?.name}
          </span>
        ) : field === "Code" || field === "Brand" ? (
          <span
            // @ts-ignore
            style={synopsisStyles}
            title={params?.cell?.getValue()?.toLocaleString()}
          >
            {params?.cell?.getValue()?.toLocaleString()}
          </span>
        ) : (
          <span
            // @ts-ignore
            style={{ ...synopsisStyles, maxWidth: "60px" }}
            title={params?.cell?.getValue()?.toLocaleString()}
          >
            {params?.cell?.getValue()?.toLocaleString()}
          </span>
        )}
      </>
    );
  };

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "code",
        header: "CODE",
        size: 100,
        Cell: (params: any) => renderClickablecell(params),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "name",
        header: "PRODUCT NAME",
        size: 250,
        Cell: (params: any) => renderClickablecell(params),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "brand",
        header: "BRAND",
        size: 100,
        Cell: (params: any) => renderClickablecell(params),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      ...genericForwardMontsColumn({
        monthIndex:
          new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1,
        customRender: true,
        customRenderFn: (params: any) => renderClickablecell(params),
      }),
    ],
    []
  );

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Total Requirement"
            helperLabel="Based on the Probability Percentage in focus"
            isAddBtn={false}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            // width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          {isTableView ? (
            <Box>
              <div
                style={{
                  height: isMobileView ? "100%" : "80vh",
                  // width: "100%",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  padding: "16px",
                  boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
                }}
              >
                <TotalRequirementFilters
                  steelData={shortageData}
                  setSteelData={setShortageData}
                  isTableView={isTableView}
                  setIsTableView={setIsTableView}
                  pagination={pagination}
                />
                <MaterialReactTable
                  data={
                    totalRListData?.steelReqData?.length > 0
                      ? totalRListData?.steelReqData
                      : []
                  }
                  columns={columns}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableGlobalFilter={false}
                  enableTopToolbar={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableToolbarInternalActions={false}
                  enablePinning
                  initialState={{
                    columnPinning: { left: ["code", "name"] },
                  }}
                  manualPagination
                  onPaginationChange={setPagination}
                  rowCount={totalRListData?.total}
                  state={{ pagination }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [],
                  }}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e0e0e07a",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      backgroundColor: "rgb(245, 246, 250)",
                      fontWeight: "600",
                      borderRight: "1px solid #e0e0e07a",
                      boxShadow: 0,
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      borderRight: "1px solid #e0e0e07a",
                      boxShadow: 0,
                    },
                  }}
                />
              </div>
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  height: isMobileView ? "100%" : "80vh",
                  // width: "100%",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  padding: "16px",
                  boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
                }}
              >
                <TotalRequirementWithoutFilters
                  isTableView={isTableView}
                  setIsTableView={setIsTableView}
                />
                <TotalRequirementChart />
              </div>
            </Box>
          )}
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};
