import { MRT_ColumnDef } from "material-react-table";

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const genericForwardMontsColumn = ({
  monthIndex,
  customRender,
  customRenderFn,
  width = 50,
  sortable = true,
}: any): any => {
  const finalColums = [];
  const currentMonth = months[monthIndex];
  const index = months?.indexOf(currentMonth);

  for (let i = 0; i < 12; i++) {
    const nextIndex = (index + i) % 12;
    const monthHeader = months[nextIndex];

    let currentYear = new Date().getFullYear();
    if (index === 11) currentYear--;
    if (nextIndex < index) currentYear++;

    const newColums: any = {
      accessorKey: `${monthHeader}-${currentYear}`,
      header: `${monthHeader}-${currentYear}`,
      size: width,
      sortable: sortable,
      enablePinning: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    };

    if (customRender) {
      newColums.Cell = customRenderFn;
    }
    finalColums.push(newColums);
  }

  return finalColums as MRT_ColumnDef[];
};
