import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { QuickSearchToolbarProps } from "../../component/search/types";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import { RootState } from "../../store/store";
import { dateFormater } from "../schedule/AddScheduleOrder";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import { truncateText } from "../../utils/truncateText";
import theme from "../../Themes";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import titleCase from "../../utils/titleCase";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { filterData } = useSelector((state: RootState) => state.steelState);
  const { isTableView, setIsTableView } = props;
  const { setSteelData, steelData } = props;
  const [value, setValue] = useState<string[] | []>([]);

  const updateSteelData = (
    value: string | number | string[],
    name: any,
    event?: any
  ) => {
    if (name === "code") {
      // @ts-ignore
      if (value.find((str: any) => str === "All")) value = [];
    }
    if (name === "minShortageDate" || name === "maxShortageDate") {
      if (event) value = event.toISODate();
      else value = "";
    }
    setSteelData({ ...steelData, [name]: value !== "All" ? value : "" });
  };

  const clearAllFilters = () => {
    setValue([]);
    setSteelData({
      name: "",
      grade: "",
      code: [],
      tempOrderId: "",
      // shortageRange: "",
      probability: "75 - 100",
      minShortageDate: "",
      maxShortageDate: "",
      location: masterLocation.locationId,
    });
  };

  const renderTags = (value: any, getTagProps: any) => {
    if (value.length === 0) {
      return null;
    }

    const totalSelectedCount = value.length - 1;
    const firstOption = value[0];

    return (
      <>
        <Chip
          label={truncateText(firstOption, 7)}
          {...getTagProps({ index: 0 })}
          size="small"
        />
        {totalSelectedCount > 0 ? <span>{`+${totalSelectedCount}`}</span> : ""}
      </>
    );
  };
  if (filterData?.allowedLocations?.length) {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <Autocomplete
              multiple
              id="checkboxes-tags-code"
              size="small"
              options={filterData?.code}
              disableCloseOnSelect
              getOptionLabel={(option: string) => option}
              value={value}
              limitTags={1}
              onChange={(event: any, newValue: string[]) => {
                setValue(newValue);
                updateSteelData(newValue, `code`);
              }}
              renderTags={renderTags}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: "-10px" }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: "226px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                    },
                  }}
                  label="Code"
                />
              )}
            />
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="name"
              label={`Product Name`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.name}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.names?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.names?.map((option: any, i: number) => (
                <MenuItem key={i} value={Object.values(option)[0]}>
                  {/* @ts-ignore */}
                  {Object.values(option)[0]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="grade"
              label={`Grade`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.grade}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              {filterData?.grades?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.grades?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {/* <Box className={"resp-select"}>
            <TextField
              type="text"
              name="shortageRange"
              label={`Range`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.shortageRange}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              <MenuItem value={"All"}>None</MenuItem>
              <MenuItem value={"gt0"}>Above Zero</MenuItem>
              <MenuItem value={"0"}>Zero</MenuItem>
              <MenuItem value={"lt0"}>Below Zero</MenuItem>
            </TextField>
          </Box> */}
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="probability"
              label={`Probability %`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.probability}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              <MenuItem value={"None"}>None</MenuItem>
              <MenuItem value={"100"}>100%</MenuItem>
              <MenuItem value={"75 - 100"}>75% - 100%</MenuItem>
              <MenuItem value={"Below 75"}>Below 75%</MenuItem>
            </TextField>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="Start Date"
                slotProps={{
                  textField: {
                    size: "small",
                    id: "minShortageDate",
                    name: "minShortageDate",
                    InputProps: {
                      sx: {
                        width: "108px",
                        height: "35px",
                        fontSize: "14px",
                      },
                    },
                    InputLabelProps: {
                      sx: {
                        fontSize: "14px",
                      },
                    },
                  },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                value={
                  steelData?.minShortageDate
                    ? DateTime.fromJSDate(new Date(steelData?.minShortageDate))
                    : null
                }
                format="dd/MM/yyyy"
                onChange={(e: any) => updateSteelData("", "minShortageDate", e)}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="End Date"
                value={
                  steelData?.maxShortageDate
                    ? DateTime.fromJSDate(new Date(steelData?.maxShortageDate))
                    : null
                }
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    size: "small",
                    id: "maxShortageDate",
                    name: "maxShortageDate",
                    InputProps: {
                      sx: {
                        width: "108px",
                        height: "35px",
                        fontSize: "14px",
                      },
                    },
                    InputLabelProps: {
                      sx: {
                        fontSize: "14px",
                      },
                    },
                  },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                onChange={(e: any) => updateSteelData("", "maxShortageDate", e)}
              />
            </LocalizationProvider>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="location"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.location}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.location?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
              {filterData?.location?.length ? (
                filterData?.location?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option._id}>
                    {titleCase(option.fullName)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup aria-label="outlined primary button group">
            <Tooltip title="Tabular View">
              <Button
                variant={isTableView ? "contained" : "outlined"}
                onClick={() => setIsTableView(true)}
                size="small"
                sx={{
                  width: "54px",
                  height: "35px",
                }}
              >
                <TableChartOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Chart View">
              <Button
                variant={!isTableView ? "contained" : "outlined"}
                onClick={() => setIsTableView(false)}
                size="small"
                sx={{
                  width: "54px",
                  height: "35px",
                }}
              >
                <StackedLineChartIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const ShortageFilters = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
export const ShortageWithoutFilters = (props: QuickSearchToolbarProps) => {
  const { isTableView, setIsTableView } = props;
  return (
    <Box display={"flex"} alignItems={"center"} width={"100%"} pt={2}>
      <Box
        sx={{
          flex: "1",
          display: "flex",
          justifyContent: "end",
          marginBottom: "15px",
        }}
      >
        <ButtonGroup aria-label="outlined primary button group">
          <Tooltip title="Tabular View">
            <Button
              variant={isTableView ? "contained" : "outlined"}
              onClick={() => setIsTableView(true)}
            >
              <TableChartOutlinedIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Chart View">
            <Button
              variant={!isTableView ? "contained" : "outlined"}
              onClick={() => setIsTableView(false)}
            >
              <StackedLineChartIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
