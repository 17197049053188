import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";
import { generateTrayNameUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

function formatDate(input: any) {
  var datePart = input.match(/\d+/g),
    year = datePart[0].substring(0), // get only two digits
    month = datePart[1],
    day = datePart[2];

  return day + "-" + month + "-" + year;
}
export const getScheduleList = createAsyncThunk(
  "GET_ALL_SCHEDULE",
  async (postData: any, thunkApi) => {
    let {
      trayName,
      customerName,
      probability,
      deliveryStartDate,
      deliveryEndDate,
      processingStartDate,
      processingEndDate,
      page,
      pageSize,
      location,
    } = postData;
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;

    deliveryStartDate = reformatDates(deliveryStartDate);
    deliveryEndDate = reformatDates(deliveryEndDate);
    processingStartDate = reformatDates(processingStartDate);
    processingEndDate = reformatDates(processingEndDate);

    try {
      const trayNameUrl = generateTrayNameUrl(trayName);

      const url = `trayOrder?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${trayNameUrl}${customerName ? `customerName=${customerName}&` : ""}${
        probability ? `probability=${probability}&` : ""
      }${deliveryStartDate ? `deliveryStartDate=${deliveryStartDate}&` : ""}${
        deliveryEndDate ? `deliveryEndDate=${deliveryEndDate}&` : ""
      }${
        processingStartDate ? `processingStartDate=${processingStartDate}&` : ""
      }${processingEndDate ? `processingEndDate=${processingEndDate}&` : ""}${
        location !== "all" ? `locationId=${location}&` : ""
      }`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      const tempo = JSON.parse(JSON.stringify(finelObj));
      const temp = finelObj.rows.map((t: any) => ({
        ...t,
        trayName: t.tray.name,
        purchaseDate: reformatDates(t?.purchaseDate),
        deliveryDate: reformatDates(t?.deliveryDate),
        processingDate: reformatDates(t?.processingDate),
      }));
      tempo.rows = temp;
      return tempo;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching orders!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getSummaryList = createAsyncThunk(
  "GET_ALL_SUMMARY_SCHEDULE",
  async (postData: any, thunkApi) => {
    let {
      trayName,
      customerName,
      probability,
      deliveryStartDate,
      deliveryEndDate,
      page,
      pageSize,
      location,
    } = postData;
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;

    deliveryStartDate = reformatDates(deliveryStartDate);
    deliveryEndDate = reformatDates(deliveryEndDate);

    try {
      const trayNameUrl = generateTrayNameUrl(trayName);

      const url = `trayOrder/summary?${trayNameUrl}${
        customerName ? `customerName=${customerName}&` : ""
      }${probability ? `probability=${probability}&` : ""}${
        deliveryStartDate ? `deliveryStartDate=${deliveryStartDate}&` : ""
      }${deliveryEndDate ? `deliveryEndDate=${deliveryEndDate}&` : ""}${
        page ? `page=${page}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        location ? `locationId=${location}&` : ""
      }`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };

      const temp = finelObj.rows.map((order: any) => {
        const t = {
          trayName: order?.trayName,
        };
        order.orders.forEach((a: any, index: number) => {
          const c = getMonthName(order?.orders[index].month).toLowerCase();
          const d = order?.orders[index].count;
          // @ts-ignore
          t[c] = d;
        });
        return t;
      });
      finelObj.rows = temp;
      return finelObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching summary of orders!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addSchedule = createAsyncThunk(
  "ADD_SCHEDULE",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("trayOrder", postData.data, {
        signal,
      });
      if (response) {
        postData?.callback();
        if (response?.data?.status === 0) {
          dispatch(toggleSnacker(errorOpen(response?.data?.message)));

          // dispatch(
          //   toggleSnacker(
          //     errorOpen(
          //       "No steel has been allocated to this asset. Please go to Steel per asset to allocate steel!"
          //     )
          //   )
          // );
        } else {
          dispatch(
            toggleSnacker(successOpen("Order has been added successfully!"))
          );
        }
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while adding new order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditSchedule = createAsyncThunk(
  "GET_EDIT_SCHEDULE",
  async (params: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(
        `trayOrder/?trayOrderId=${params.id}&locationId=${params.locationId}`,
        {
          signal,
        }
      );
      if (response) {
        const data = response?.data?.data;
        const temp = {
          ...data,
        };
        return temp;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching the order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const updateSchedule = createAsyncThunk(
  "UPDATE_SCHEDULE",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;

    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch("trayOrder", postData?.data, { signal });
      if (response) {
        postData?.callback();
        const data = response?.data?.result;
        dispatch(
          toggleSnacker(successOpen("Order has been successfully updated!"))
        );
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while updating the order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteSchedule = createAsyncThunk(
  "DELETE_SCHEDULE",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `trayOrder?trayOrderId=${postData?.trayOrderId}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response?.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Order has been successfully deleted!"
          )
        )
      );
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while deleting the order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterData = createAsyncThunk(
  "GET_DROPDOWN_SCHEDULE",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `trayOrder/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, postData, { signal });
      const data = response?.data?.data;
      const tempData = {
        trayName: data.assetNames,
        probability: data.probability,
        customerName: data.customerNames,
        location: data.allowedLocations,
        deliveryStartDate: "",
        deliveryEndDate: "",
      };
      return tempData;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching the orders!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getConfigSchedule = createAsyncThunk(
  "GET_CONFIG_SCHEDULE",
  async (params: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(`trayOrder/bom/?trayOrderId=${params}`, {
        signal,
      });
      if (response) {
        const data = response?.data?.data;
        const temp = {
          ...data,
        };
        return temp;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching the order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

function getMonthName(monthNumber: any) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: "short" });
}
