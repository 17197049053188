import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Modal,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSteelOnOrder,
  deleteSteelOnOrderItem,
} from "../../api/steelonorder/Api.service";
import {
  Order,
  getComparator,
  stableSort,
} from "../steelPerTray/SteelPerTrayTable";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import titleCase from "../../utils/titleCase";
import TableRowNoResults from "../steelPerTray/TableNoResults";
import { reformatDates } from "../../utils/reformatDates";

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort, tableCells } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {tableCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: "600",
              backgroundColor: "#f5f6fa",
              border: "1px solid #e0e0e07a",
              borderBottom: "0",
              textAlign: "center",
            }}
          >
            {headCell.label && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                disabled={headCell.id === "action"}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function SteelOnOrderRow(props: any): JSX.Element {
  const { row, index, refreshSteelOnOrder, onEdit, isPurchaseView } = props;
  const [open, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("code");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        row[`${isPurchaseView ? `steelorderitems` : `shipmentitems`}`],
        getComparator(order, orderBy)
      ),
    [
      order,
      orderBy,
      row,
      row[`${isPurchaseView ? `steelorderitems` : `shipmentitems`}`],
    ]
  );

  const collapsedStyles = {
    // width: "110px",
    // maxWidth: "150px",
    border: "1px solid #e0e0e07a",
    width: "100px !important",
    textAlign: "center",
  };

  const tableCellStyle = {
    // background: "red",
    borderRight: "1px solid #e0e0e07a",
    borderBottom: "1px solid #e0e0e07a",
    textAlign: "center",
  };

  const synopsisStyles = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100px",
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleClick = () => {
    setOpenDeleteModal(true);
  };

  const tableCells: any = [
    {
      id: "lineId",
      label: "LINE ID",
    },
    {
      id: "code",
      label: "CODE",
    },
    {
      id: "name",
      label: "PRODUCT",
    },
    {
      id: "grade",
      label: "GRADE",
    },
    ...(!isPurchaseView
      ? [
          {
            id: "receivedQty",
            label: "RECEIVED",
          },
        ]
      : [
          {
            id: "totalOrderedQty",
            label: "ORDERED",
          },
          {
            id: "totalReceivedQty",
            label: "RECEIVED",
          },
          {
            id: "totalOutstandingQty",
            label: "OUTSTANDING",
          },
          {
            id: "forecastedDate",
            label: "FORECASTED",
          },
          {
            id: "status",
            label: "STATUS",
          },
        ]),

    {
      id: "actions",
      label: "ACTIONS",
    },
  ];

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`uncollapsed-row-${index}`}
      >
        <TableCell sx={tableCellStyle}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ ...tableCellStyle, ...synopsisStyles }}
          title={row?.purchaseOrder}
        >
          {row?.purchaseOrder}
        </TableCell>
        <TableCell
          align="left"
          sx={{ ...tableCellStyle, ...synopsisStyles }}
          title={row?.supplier}
        >
          {row?.supplier}
        </TableCell>
        {!isPurchaseView ? (
          <>
            <TableCell
              align="left"
              sx={{ ...tableCellStyle, ...synopsisStyles }}
              title={row?.name ?? "-"}
            >
              {row?.name ?? "-"}
            </TableCell>
            <TableCell
              align="left"
              sx={{ ...tableCellStyle, ...synopsisStyles }}
              title={row?.shipmentType ?? "-"}
            >
              {titleCase(row?.shipmentType) ?? "-"}
            </TableCell>
            <TableCell
              align="left"
              sx={{ ...tableCellStyle, ...synopsisStyles }}
              title={reformatDates(row?.purchaseDate) ?? "-"}
            >
              {reformatDates(row?.purchaseDate) ?? "-"}
            </TableCell>
            <TableCell
              align="left"
              sx={{ ...tableCellStyle, ...synopsisStyles }}
              title={reformatDates(row?.deliveryDate) ?? "-"}
            >
              {reformatDates(row?.deliveryDate) ?? "-"}
            </TableCell>
          </>
        ) : (
          <TableCell
            align="left"
            sx={{ ...tableCellStyle, ...synopsisStyles }}
            title={reformatDates(row?.purchaseDate) ?? "-"}
          >
            {reformatDates(row?.purchaseDate) ?? "-"}
          </TableCell>
        )}

        <TableCell
          align="left"
          sx={tableCellStyle}
          title={row?.location?.fullName ?? "-"}
        >
          {titleCase(row?.location?.fullName)}
        </TableCell>
        <TableCell
          align="left"
          sx={{ ...tableCellStyle, fontWeight: "600", borderRight: "0" }}
        >
          {userInfo?.jobRole?.key === "supply_chain_manager" ||
          userInfo?.jobRole?.key === "location_admin" ? (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => onEdit(row?._id, row)}
                  disabled={
                    !props.permission ||
                    row?.locationId !== userInfo?.ownLocation.locationId
                  }
                >
                  <EditOutlinedIcon
                    color={
                      !props.permission ||
                      row?.locationId !== userInfo?.ownLocation.locationId
                        ? "disabled"
                        : "error"
                    }
                  ></EditOutlinedIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={handleClick}
                  disabled={
                    !props.permission ||
                    row?.locationId !== userInfo?.ownLocation.locationId
                  }
                >
                  <DeleteForeverOutlinedIcon
                    color={
                      !props.permission ||
                      row?.locationId !== userInfo?.ownLocation.locationId
                        ? "disabled"
                        : "error"
                    }
                  ></DeleteForeverOutlinedIcon>
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => onEdit(row?._id, row)}
                  disabled={!props.permission}
                >
                  <EditOutlinedIcon
                    color={props.permission ? "error" : "disabled"}
                  ></EditOutlinedIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={handleClick} disabled={!props.permission}>
                  <DeleteForeverOutlinedIcon
                    color={props.permission ? "error" : "disabled"}
                  ></DeleteForeverOutlinedIcon>
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCell>
      </TableRow>
      <TableRow key={`parent-collapsed-row-${index}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="collapsed emissions data">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  tableCells={tableCells}
                  rowCount={row?.length}
                />
                <TableBody>
                  {visibleRows?.length ? (
                    visibleRows?.map((collapsedRow: any) => {
                      const handleClick = () => {
                        dispatch(
                          deleteSteelOnOrderItem({
                            isPurchaseView,
                            id: collapsedRow?._id,
                            locationId: row?.locationId,
                            callback: refreshSteelOnOrder,
                          })
                        ).then(() => {
                          handleClose();
                        });
                      };
                      return (
                        <TableRow key={`rc-collapsed-row-${index}`}>
                          <TableCell
                            align="left"
                            sx={{ ...collapsedStyles, ...synopsisStyles }}
                            title={collapsedRow?.lineId}
                          >
                            {collapsedRow?.lineId}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            sx={{ ...collapsedStyles, ...synopsisStyles }}
                            title={collapsedRow?.code}
                          >
                            {collapsedRow?.code}
                          </TableCell>
                          <TableCell
                            align="left"
                            title={collapsedRow?.name}
                            sx={{ ...collapsedStyles, ...synopsisStyles }}
                          >
                            {collapsedRow?.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            title={collapsedRow?.grade}
                            sx={{ ...collapsedStyles, ...synopsisStyles }}
                          >
                            {collapsedRow?.grade}
                          </TableCell>
                          {!isPurchaseView ? (
                            <>
                              <TableCell align="left" sx={collapsedStyles}>
                                {collapsedRow?.receivedQty ?? "-"}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align="left" sx={collapsedStyles}>
                                {collapsedRow?.totalOrderedQty}
                              </TableCell>
                              <TableCell align="left" sx={collapsedStyles}>
                                {collapsedRow?.totalReceivedQty ?? "-"}
                              </TableCell>
                              <TableCell align="left" sx={collapsedStyles}>
                                {collapsedRow?.totalOutstandingQty ?? "-"}
                              </TableCell>
                              <TableCell align="left" sx={collapsedStyles}>
                                {reformatDates(collapsedRow?.forecastedDate) ??
                                  `-`}
                              </TableCell>
                              <TableCell align="left" sx={collapsedStyles}>
                                {titleCase(collapsedRow?.status) ?? "-"}
                              </TableCell>
                            </>
                          )}

                          <TableCell
                            align="left"
                            sx={{
                              ...tableCellStyle,
                              fontWeight: "600",
                              borderRight: "0",
                            }}
                          >
                            {userInfo?.jobRole?.key ===
                              "supply_chain_manager" ||
                            userInfo?.jobRole?.key === "location_admin" ? (
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={handleClick}
                                    disabled={
                                      !props.permission ||
                                      row?.locationId !==
                                        userInfo?.ownLocation.locationId
                                    }
                                  >
                                    <DeleteForeverOutlinedIcon
                                      color={
                                        !props.permission ||
                                        row?.locationId !==
                                          userInfo?.ownLocation.locationId
                                          ? "disabled"
                                          : "error"
                                      }
                                    ></DeleteForeverOutlinedIcon>
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            ) : (
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={handleClick}
                                    disabled={!props.permission}
                                  >
                                    <DeleteForeverOutlinedIcon
                                      color={
                                        props.permission ? "error" : "disabled"
                                      }
                                    ></DeleteForeverOutlinedIcon>
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRowNoResults colSpan={tableCells?.length} />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Deleting this will impact the calculations. Are you sure you want
              to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteSteelOnOrder({
                      id: row?._id,
                      locationId: row?.locationId,
                      callback: refreshSteelOnOrder,
                      isPurchaseView,
                    })
                  ).then(() => {
                    handleClose();
                  });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export const findThatStatus = (status: string) => {
  switch (status) {
    case "INCOMING_SHIPMENT":
      return "Incoming Shipment";
    case "BACK_ORDER":
      return "Back Order";
    case "ON_ORDER":
      return "On Order";
    case "FULFILLED":
      return "Fulfilled";
  }
};
