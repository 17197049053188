import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { HiSearch } from "react-icons/hi";
import DownloadIcon from "@mui/icons-material/Download";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import { finalUrl } from "../../api/Api.utils";
import { useDispatch, useSelector } from "react-redux";
import { QuickSearchToolbarProps } from "../../component/search/types";
import { AppDispatch, RootState } from "../../store/store";
import { setSelectedFilter } from "./slice/TotalRSlice";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import { truncateText } from "../../utils/truncateText";
import theme from "../../Themes";
import { reformatDates } from "../../utils/reformatDates";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { GiWeight } from "react-icons/gi";
import titleCase from "../../utils/titleCase";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const dispatch: AppDispatch = useDispatch();
  const { filterData } = useSelector((state: RootState) => state.steelState);
  const { isTableView, setIsTableView } = props;
  const { setSteelData, steelData } = props;

  const [value, setValue] = useState<string[] | []>([]);

  const renderTags = (value: any, getTagProps: any) => {
    if (value.length === 0) {
      return null;
    }

    const totalSelectedCount = value.length - 1;
    const firstOption = value[0];

    return (
      <>
        <Chip
          label={truncateText(firstOption, 7)}
          {...getTagProps({ index: 0 })}
          size="small"
        />
        {totalSelectedCount > 0 ? <span>{`+${totalSelectedCount}`}</span> : ""}
      </>
    );
  };

  const updateSteelData = (
    value: string | number | string[],
    name: any,
    event?: any
  ) => {
    if (name === "code") {
      // @ts-ignore
      if (value.find((str: any) => str === "All")) value = [];
    }
    if (
      name === "purchaseStartDate" ||
      name === "purchaseEndDate" ||
      name === "processingStartDate" ||
      name === "processingEndDate"
    ) {
      if (event) value = event.toISODate();
      else value = "";
    }

    setSteelData({ ...steelData, [name]: value !== "All" ? value : "" });
    if (name === "location") {
      dispatch(
        setSelectedFilter({ field: "location", value: value as string })
      );
    }
    if (name === "probability")
      dispatch(
        setSelectedFilter({ field: "probability", value: value as string })
      );
  };

  const onDownload = async () => {
    let {
      code,
      location,
      name,
      probability,
      processingEndDate,
      processingStartDate,
      purchaseEndDate,
      purchaseStartDate,
    } = steelData;

    purchaseStartDate = reformatDates(purchaseStartDate);
    purchaseEndDate = reformatDates(purchaseEndDate);
    processingStartDate = reformatDates(processingStartDate);
    processingEndDate = reformatDates(processingEndDate);

    const codeUrl = generateCodeUrl(code);
    const paramsUrl = `${codeUrl}${name ? `name=${name}&` : ""}${
      probability ? `probability=${probability}&` : ""
    }${purchaseEndDate ? `purchaseEndDate=${purchaseEndDate}&` : ""}${
      processingEndDate ? `processingEndDate=${processingEndDate}&` : ""
    }${
      processingStartDate ? `processingStartDate=${processingStartDate}&` : ""
    }${purchaseStartDate ? `purchaseStartDate=${purchaseStartDate}&` : ""}${
      location !== "all" ? `locationId=${location}&` : ""
    }`;

    const downloadUrl = `${finalUrl}totalRequirement/csvReport?${paramsUrl}`;
    window.location.href = downloadUrl;
  };

  const onDownloadKG = async () => {
    let {
      code,
      location,
      name,
      probability,
      processingEndDate,
      processingStartDate,
      purchaseEndDate,
      purchaseStartDate,
    } = steelData;

    purchaseStartDate = reformatDates(purchaseStartDate);
    purchaseEndDate = reformatDates(purchaseEndDate);
    processingStartDate = reformatDates(processingStartDate);
    processingEndDate = reformatDates(processingEndDate);

    const codeUrl = generateCodeUrl(code);
    const paramsUrl = `${codeUrl}${name ? `name=${name}&` : ""}${
      probability ? `probability=${probability}&` : ""
    }${purchaseEndDate ? `purchaseEndDate=${purchaseEndDate}&` : ""}${
      processingEndDate ? `processingEndDate=${processingEndDate}&` : ""
    }${
      processingStartDate ? `processingStartDate=${processingStartDate}&` : ""
    }${purchaseStartDate ? `purchaseStartDate=${purchaseStartDate}&` : ""}${
      location !== "all" ? `locationId=${location}&` : ""
    }`;

    const downloadUrl = `${finalUrl}totalRequirement/kgReport?${paramsUrl}`;
    window.location.href = downloadUrl;
  };

  const clearAllFilters = () => {
    setValue([]);
    setSteelData({
      name: "",
      grade: "",
      code: [],
      probability: "75 - 100",
      purchaseEndDate: "",
      processingEndDate: "",
      processingStartDate: "",
      purchaseStartDate: "",
      // purchaseStartDate: "",
      location: masterLocation.locationId,
    });
  };

  if (filterData?.allowedLocations?.length) {
    return (
      <Box display={"flex"} alignItems={"center"} width={"100%"}>
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <Autocomplete
              multiple
              id="checkboxes-tags-code"
              size="small"
              options={filterData?.code}
              disableCloseOnSelect
              getOptionLabel={(option: string) => option}
              value={value}
              limitTags={1}
              renderTags={renderTags}
              onChange={(event: any, newValue: string[]) => {
                setValue(newValue);
                updateSteelData(newValue, `code`);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: "100px !important" }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: "-10px" }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: "226px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                    },
                  }}
                  label="Code"
                />
              )}
            />
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="name"
              label={`Product Name`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.name}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.names?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.names?.map((option: any, i: number) => (
                <MenuItem key={i} value={Object.values(option)[0]}>
                  {/* @ts-ignore */}
                  {Object.values(option)[0]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="grade"
              label={`Grade`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.grade}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              {filterData?.grades?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.grades?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="probability"
              label={`Probability %`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.probability}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              <MenuItem value={"None"}>None</MenuItem>
              <MenuItem value={"100"}>100%</MenuItem>
              <MenuItem value={"75 - 100"}>75% - 100%</MenuItem>
              <MenuItem value={"Below 75"}>Below 75%</MenuItem>
            </TextField>
          </Box>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Purchase Start Date"
              format="dd/MM/yyyy"
              value={
                steelData?.purchaseStartDate
                  ? DateTime.fromJSDate(new Date(steelData?.purchaseStartDate))
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "purchaseStartDate",
                  name: "purchaseStartDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(e: any) => updateSteelData("", "purchaseStartDate", e)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Purchase End Date"
              format="dd/MM/yyyy"
              value={
                steelData?.purchaseEndDate
                  ? DateTime.fromJSDate(new Date(steelData?.purchaseEndDate))
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "purchaseEndDate",
                  name: "purchaseEndDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(e: any) => updateSteelData("", "purchaseEndDate", e)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Processing Start Date"
              format="dd/MM/yyyy"
              value={
                steelData?.processingStartDate
                  ? DateTime.fromJSDate(
                      new Date(steelData?.processingStartDate)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "processingStartDate",
                  name: "processingStartDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(e: any) =>
                updateSteelData("", "processingStartDate", e)
              }
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Processing End Date"
              format="dd/MM/yyyy"
              value={
                steelData?.processingEndDate
                  ? DateTime.fromJSDate(new Date(steelData?.processingEndDate))
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "processingEndDate",
                  name: "processingEndDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(e: any) => updateSteelData("", "processingEndDate", e)}
            />
          </LocalizationProvider>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="location"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.location}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.location?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
              {filterData?.location?.length ? (
                filterData?.location?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option._id}>
                    {titleCase(option.fullName)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup aria-label="outlined primary button group">
            <Tooltip title="Tabular View">
              <Button
                variant={isTableView ? "contained" : "outlined"}
                onClick={() => setIsTableView(true)}
                sx={{
                  width: "54px",
                  height: "35px",
                }}
              >
                <TableChartOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Chart View">
              <Button
                variant={!isTableView ? "contained" : "outlined"}
                onClick={() => setIsTableView(false)}
                sx={{
                  width: "54px",
                  height: "35px",
                }}
              >
                <StackedLineChartIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
          <ButtonGroup aria-label="outlined primary button group">
            <div style={{ marginRight: "4px" }}>
              <Tooltip title="Download KG Report">
                <Button
                  startIcon={<GiWeight />}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={onDownloadKG}
                  sx={{
                    width: "52px",
                    height: "35px",
                    paddingRight: "10px",
                    borderRadius: "4px !important",
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Download Report">
                <Button
                  startIcon={<DownloadIcon />}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={onDownload}
                  sx={{
                    width: "52px",
                    height: "35px",
                    paddingRight: "10px",
                    borderRadius: "4px !important",
                  }}
                />
              </Tooltip>
            </div>
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const TotalRequirementFilters = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};

export const TotalRequirementWithoutFilters = (
  props: QuickSearchToolbarProps
) => {
  const { isTableView, setIsTableView } = props;
  return (
    <Box display={"flex"} alignItems={"center"} width={"100%"}>
      <Box
        sx={{
          flex: "1",
          display: "flex",
          justifyContent: "end",
          marginBottom: "15px",
        }}
      >
        <ButtonGroup aria-label="outlined primary button group">
          <Tooltip title="Tabular View">
            <Button
              variant={isTableView ? "contained" : "outlined"}
              onClick={() => setIsTableView(true)}
            >
              <TableChartOutlinedIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Chart View">
            <Button
              variant={!isTableView ? "contained" : "outlined"}
              onClick={() => setIsTableView(false)}
            >
              <StackedLineChartIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
