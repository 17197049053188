import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import {
  ADD_SHORTAGE,
  GET_ALL_SHORTAGE,
  GET_DROPDOWN_SHORTAGE,
  GET_REPLACEMENT_DATA,
  GET_ALL_CHART_SHORTAGE,
  GET_EDIT_TEMP_ORDER,
  DELETE_TEMP_ORDER,
} from "../ActionConstants";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import API from "../Api.utils";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

export const getShortageList = createAsyncThunk(
  GET_ALL_SHORTAGE,
  async (postData: any, thunkApi) => {
    let {
      name,
      grade,
      code,
      tempOrderId,
      // shortageRange,
      minShortageDate,
      maxShortageDate,
      page,
      pageSize,
      location,
      probability,
    } = postData;

    minShortageDate = reformatDates(minShortageDate);
    maxShortageDate = reformatDates(maxShortageDate);

    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const codeUrl = generateCodeUrl(code);
      const url = `shortage?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${name ? `name=${name}&` : ""}${
        grade ? `grade=${grade}&` : ""
      }${codeUrl}${tempOrderId ? `tempTrayOrderId=${tempOrderId}&` : ""}${
        minShortageDate ? `startDate=${minShortageDate}&` : ""
      }${maxShortageDate ? `endDate=${maxShortageDate}&` : ""}${
        location !== "all" ? `locationId=${location}&` : ""
      }${probability ? `probability=${probability}&` : ""}`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
        shortageArray: data?.shortageArray?.map((item: any) => ({
          ...item,
          // name: `${item.name}, ${item.length}mm, ${item.width}mm`,
        })),
      };
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );

      return finelObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Shortage Data ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getChartShortageList = createAsyncThunk(
  GET_ALL_CHART_SHORTAGE,
  async (postData: any, thunkApi) => {
    const { grade, thickness, width, length, page, pageSize } = postData;
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `shortage?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${grade ? `grade=${grade}&` : ""}${
        thickness ? `thickness=${thickness}&` : ""
      }${width ? `width=${width}&` : ""}${length ? `length=${length}` : ""}`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      return finelObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Shortage Data ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addShortage = createAsyncThunk(
  ADD_SHORTAGE,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("tempTrayOrder", postData, { signal });
      const data = response?.data?.result;
      dispatch(toggleSnacker(successOpen("Temp Order Added Successfully!")));
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Saving Shortage, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterDataShortage = createAsyncThunk(
  GET_DROPDOWN_SHORTAGE,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `shortage/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, { signal });
      const data = {
        ...response?.data?.data,
        location: response?.data?.data?.allowedLocations,
      };
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Drop Down Data ...!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditTempOrder = createAsyncThunk(
  GET_EDIT_TEMP_ORDER,
  async (id: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(
        `tempTrayOrder?page=1&pageSize=1&locationId=${id}`,
        {
          signal,
        }
      );
      if (response) {
        const data = response?.data?.data;

        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Steel, Please Try Again"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteTemopOrder = createAsyncThunk(
  DELETE_TEMP_ORDER,
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `tempTrayOrder?trayOrderId=${postData?.id}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      const data = response?.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Temporary order for asset has been deleted successfully!"
          )
        )
      );
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while deleting temporary order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getReplacementData = createAsyncThunk(
  GET_REPLACEMENT_DATA,
  async (shortageData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const url = `shortage/replacement?steelId=${shortageData.id}&monthName=${shortageData.monthName}&shortage=${shortageData.value}&tempTrayPOOrderId=${shortageData.tempTrayPOOrderId}`;
      const { data } = await API.get(url, { signal });

      if (data) {
        const dataArray = data?.data.replacementSteel;
        const newDataArray = dataArray?.map((item: any, index: any) => ({
          ...item,
          id: `${item._id}-${index}`,
        }));
        return newDataArray;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Fetching Data ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
