import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import WindowIcon from "@mui/icons-material/Window";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import GradingIcon from "@mui/icons-material/Grading";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import MenuIcon from "@mui/icons-material/Menu";
import { Collapse, List, ListItemButton, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import { appEnvUrl } from "../../api/Api.utils";
import "./style.css";
import { LocationSelector } from "./LocationSelector";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useSelector } from "react-redux";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)})`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = (props) => {
  const { open, setOpen } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [openMaster, setOpenMaster] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const { userInfo } = useSelector((state) => state.globalAppState);
  const { pathname } = location;

  const handleMasterIconClick = () => {
    setOpen(true);
    setOpenMaster(true);
  };

  const handleReportsIconClick = () => {
    setOpen(true);
    setOpenReports(true);
  };

  useEffect(() => {
    const temp = pathname.split("/");
    if (
      temp.includes("steel") ||
      temp.includes("asset") ||
      temp.includes("steel-per-asset") ||
      temp.includes("schedule") ||
      (!temp[0] && !temp[1])
    )
      handleMasterIconClick();
    else if (
      temp.includes("steel-forecast-demand") ||
      temp.includes("master-schedule")
    )
      handleReportsIconClick();
  }, []);

  useEffect(() => {
    if (!open) {
      setOpenMaster(false);
      setOpenReports(false);
    }
  }, [open]);

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            top: 64,
            zIndex: 1299,
            paddingTop: "20px",
          },
        }}
      >
        <div className="sidebar-container">
          <div
            className="sidebar-container-items"
            style={{
              pointerEvents: "none",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
          >
            <span style={{ marginLeft: !open && "1rem" }}></span>
            <span style={{ visibility: !open && "hidden" }}>
              {appEnvUrl[0]}
            </span>
          </div>
          <div
            // onClick={() => setOpenReports(!openReports)}
            className="sidebar-container-items"
          >
            <Tooltip title="Location">
              <span
                style={{ marginLeft: !open && "1rem" }}
                // onClick={handleReportsIconClick}
              >
                <PlaceIcon />
              </span>
            </Tooltip>
            <div style={{ display: !open && "none" }}>
              <LocationSelector />
            </div>
          </div>
          {(userInfo?.jobRole.key === "super_user" ||
            userInfo?.jobRole.key === "location_admin") && (
            <div
              onClick={() => {
                navigate("./user-management");
              }}
              className={
                pathname.split("/").includes("user-management")
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <Tooltip title="User Management">
                <span style={{ marginLeft: !open && "1rem" }}>
                  <SupervisorAccountIcon />
                </span>
              </Tooltip>
              <span style={{ display: !open && "none" }}>User Management</span>
            </div>
          )}

          <div
            onClick={() => setOpenMaster(!openMaster)}
            className="sidebar-container-items"
          >
            <Tooltip title="Master">
              <span
                style={{ marginLeft: !open && "1rem" }}
                onClick={handleMasterIconClick}
              >
                <FeedOutlinedIcon />
              </span>
            </Tooltip>
            <span style={{ display: !open && "none" }}>Master</span>
            {openMaster ? (
              <ExpandLessOutlinedIcon />
            ) : (
              <ExpandMoreOutlinedIcon />
            )}
          </div>
          <Collapse
            in={openMaster}
            timeout="auto"
            style={{ display: !open && "none" }}
          >
            <List>
              <ListItemButton
                onClick={() => {
                  navigate("./steel");
                }}
                className={
                  pathname.split("/").includes("steel") || pathname === "/"
                    ? "sidebar-container-items active-sidebar"
                    : "sidebar-container-items"
                }
              >
                <span style={{ display: !open && "none" }}>Steel</span>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("./asset");
                }}
                className={
                  pathname.split("/").includes("asset")
                    ? "sidebar-container-items active-sidebar"
                    : "sidebar-container-items"
                }
              >
                <span style={{ display: !open && "none" }}>Assets</span>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("./steel-per-asset");
                }}
                className={
                  pathname.split("/").includes("steel-per-asset")
                    ? "sidebar-container-items active-sidebar"
                    : "sidebar-container-items"
                }
              >
                <span style={{ display: !open && "none" }}>
                  Steel Per Asset
                </span>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("./schedule");
                }}
                className={
                  pathname.split("/").includes("schedule")
                    ? "sidebar-container-items active-sidebar"
                    : "sidebar-container-items"
                }
              >
                <span style={{ display: !open && "none" }}>Schedule</span>
              </ListItemButton>
            </List>
          </Collapse>

          <div
            onClick={() => {
              navigate("./steel-on-order");
            }}
            className={
              pathname.split("/").includes("steel-on-order")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <Tooltip title="Steel on Order">
              <span style={{ marginLeft: !open && "1rem" }}>
                <GradingIcon />
              </span>
            </Tooltip>
            <span style={{ display: !open && "none" }}>Steel on Order</span>
          </div>

          <div
            onClick={() => {
              navigate("./total-requirement");
            }}
            className={
              pathname.split("/").includes("total-requirement")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <Tooltip title="Total Requirement">
              <span style={{ marginLeft: !open && "1rem" }}>
                <WindowIcon />
              </span>
            </Tooltip>
            <span style={{ display: !open && "none" }}>Total Requirement</span>
          </div>
          <div
            onClick={() => {
              navigate("./inventory");
            }}
            className={
              pathname.split("/").includes("inventory")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <Tooltip title="Inventory">
              <span style={{ marginLeft: !open && "1rem" }}>
                <InventoryOutlinedIcon />
              </span>
            </Tooltip>
            <span style={{ display: !open && "none" }}>Inventory</span>
          </div>
          <div
            onClick={() => {
              navigate("./forecast-demand");
            }}
            className={
              pathname.split("/").includes("forecast-demand")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <Tooltip title="Forecast Demand">
              <span style={{ marginLeft: !open && "1rem" }}>
                <LeaderboardOutlinedIcon />
              </span>
            </Tooltip>
            <span style={{ display: !open && "none" }}>Forecast Demand</span>
          </div>
          <div
            onClick={() => setOpenReports(!openReports)}
            className="sidebar-container-items"
          >
            <Tooltip title="Reports">
              <span
                style={{ marginLeft: !open && "1rem" }}
                onClick={handleReportsIconClick}
              >
                <StackedLineChartIcon />
              </span>
            </Tooltip>
            <span style={{ display: !open && "none" }}>Reports</span>
            {openReports ? (
              <ExpandLessOutlinedIcon />
            ) : (
              <ExpandMoreOutlinedIcon />
            )}
          </div>
          <Collapse
            in={openReports}
            timeout="auto"
            style={{ display: !open && "none" }}
          >
            <List>
              <ListItemButton
                onClick={() => {
                  navigate("./steel-forecast-demand");
                }}
                className={
                  pathname.split("/").includes("steel-forecast-demand")
                    ? "sidebar-container-items active-sidebar"
                    : "sidebar-container-items"
                }
                sx={{
                  marginLeft: "5px",
                }}
              >
                <span style={{ display: !open && "none" }}>
                  Steel Forecast Demand
                </span>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("./master-schedule");
                }}
                className={
                  pathname.split("/").includes("master-schedule")
                    ? "sidebar-container-items active-sidebar"
                    : "sidebar-container-items"
                }
                sx={{
                  marginLeft: "5px",
                }}
              >
                <span style={{ display: !open && "none" }}>
                  Master Schedule
                </span>
              </ListItemButton>
            </List>
          </Collapse>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
