export default function titleCase(str: any) {
  if (!str) {
    return "";
  }
  const strArr = str.split(/[\s_]+/).map((word: any) => {
    if (word[0] === "(") {
      return word[0] + word[1].toUpperCase() + word.substring(2).toLowerCase();
    }
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  });
  return strArr.join(" ");
}
