import { PopUpMain } from "../../component/popUp";
import {
  Box,
  Button,
  MenuItem,
  Skeleton,
  Stack,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import uuid4 from "uuid4";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
} from "formik";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AppDispatch, RootState } from "../../store/store";
import { initialSteelOnOrderData } from "./data";
import {
  addSteelOnOrder,
  editSteelOnOrder,
} from "../../api/steelonorder/Api.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { deleteAndShift } from "../../utils/deleteAndShift";
import titleCase from "../../utils/titleCase";
import { reformatDates } from "../../utils/reformatDates";
import { clearSteelData } from "../steel/slice/SteelSlice";
import { getSteelList } from "../../api/steel/Api.service";
import moment from "moment";

const errorStyles = {
  marginLeft: "0px",
  marginRight: "0px",
};

export const AddSteelOnOrder = (props: any): JSX.Element => {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={false}>
        <Box>
          <AddSteelOnOrderScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
};

export const AddSteelOnOrderScreen = (props: any): JSX.Element => {
  const emptySteelObject = {
    lineId: "",
    steelId: "",
    code: "",
    brand: "",
    grade: "",
    totalOrderedQty: "",
    totalReceivedQty: "",
    totalOutstandingQty: "",
    status: "",
    forecastedDate: null,
  };

  const { handleClose, editData, refreshSteelOnOrder } = props;
  const { filterData } = useSelector(
    (state: RootState) => state.steelOnOrderState
  );
  const { steelListData } = useSelector((state: any) => state.steelState);

  const dispatch: AppDispatch = useDispatch();
  const { masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const [code, setCode] = React.useState({});

  const handleOnDateChange = (
    event: any,
    fieldName: any,
    Name: any,
    index: number
  ) => {
    if (
      Name.includes("purchaseDate") ||
      Name.includes("forecastedDate") ||
      Name.includes("deliveryDate")
    ) {
      if (event) {
        const tempDate = event.toISODate();
        fieldName(Name, tempDate);
      } else {
        fieldName(Name, "");
      }
    }
  };

  const handleChange = (
    event: any,
    fieldName: any,
    Name: any,
    index: any,
    values?: any
  ) => {
    let {
      target: { value },
    } = event;

    if (Name.includes("totalReceivedQty")) {
      const totalOrderedQty = values.items[index].totalOrderedQty
        ? parseFloat(values.items[index].totalOrderedQty)
        : 0;
      const totalReceivedQty = parseFloat(value || 0);
      const totalOutstandingQty = totalOrderedQty - totalReceivedQty;
      fieldName(`items[${index}].totalOutstandingQty`, totalOutstandingQty);
      if (totalReceivedQty === 0)
        fieldName(`items[${index}].status`, `ON_ORDER`);
      else if (totalOutstandingQty === 0)
        fieldName(`items[${index}].status`, `FULFILLED`);
      else fieldName(`items[${index}].status`, `BACK_ORDER`);
    }

    if (Name.includes("totalOrderedQty")) {
      const totalOrderedQty = parseFloat(value || 0);
      const totalReceivedQty = values.items[index].totalReceivedQty
        ? parseFloat(values.items[index].totalReceivedQty)
        : 0;
      const totalOutstandingQty = totalOrderedQty - totalReceivedQty;
      fieldName(`items[${index}].totalOutstandingQty`, totalOutstandingQty);
      if (totalReceivedQty === 0)
        fieldName(`items[${index}].status`, `ON_ORDER`);
      else if (totalOutstandingQty === 0)
        fieldName(`items[${index}].status`, `FULFILLED`);
      else fieldName(`items[${index}].status`, `BACK_ORDER`);
    }

    if (Name.includes("steelId")) {
      // empty steel and brand on code change
      fieldName(`items[${index}].code`, "");
      fieldName(`items[${index}].brand`, "");
      fieldName(`items[${index}].quantity`, "");
      fetchNewSteels(value, index);
    }

    if (Name.includes("locationId")) {
      values.items.forEach((value: any, index: number) => {
        fieldName(`items[${index}]["steelId"]`, "");
        fieldName(`items[${index}].["code"]`, "");
        fieldName(`items[${index}].["brand"]`, "");
      });
      dispatch(clearSteelData());
      dispatch(
        getSteelList({
          page: 1,
          pageSize: 1000,
          location: value,
        })
      );
    }

    if (Name) fieldName(Name, value);
  };

  const fetchNewSteels = (value: any, index: number) => {
    const resp = steelListData?.rows?.filter(
      (steel: any) => steel.id === value
    );
    const tempKey = `index-${index}`;
    setCode({
      ...code,
      [tempKey]: resp,
    });
  };

  const handleCallBack = () => {
    refreshSteelOnOrder();
    handleClose();
  };

  React.useEffect(() => {
    dispatch(
      getSteelList({
        page: 1,
        pageSize: 1000,
        location: editData?.locationId || userInfo?.ownLocation?.locationId,
      })
    );
  }, []);

  React.useEffect(() => {
    if (editData?._id) {
      const editSteel = editData?.items?.reduce(
        (acc: any, steel: any, index: number) => {
          const response = steelListData?.rows?.filter(
            (steelData: any) => steelData._id === steel.steelId
          );
          const tempKey = `index-${index}`;
          acc[tempKey] = response;
          return acc;
        },
        {}
      );
      setCode(editSteel);
    }
  }, [editData, steelListData]);

  const validationSchema = yup.object({
    orderNumber: yup
      .string()
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    supplier: yup
      .string()
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    items: yup.array().of(
      yup.object().shape({
        lineId: yup.number().min(1, "Line ID must be greater than 1"),
        totalOrderedQty: yup
          .number()
          .min(1, "Qty must be greater than 1")
          .max(999.99, "Qty is exceeding the limit"),
        totalReceivedQty: yup
          .number()
          .nullable()
          .min(0, "Qty must be greater than 0")
          .max(999.99, "Qty is exceeding the limit"),
        totalOutstandingQty: yup
          .number()
          .nullable()
          .min(0, "Qty must be greater than 0")
          .max(999.99, "Qty is exceeding the limit"),
      })
    ),
  });

  const initialSteelOnOrderData = {
    steelOrderId: "",
    orderNumber: "",
    supplier: "",
    purchaseDate: null,
    locationId:
      userInfo?.jobRole?.key === "supply_chain_manager" ||
      userInfo?.jobRole?.key === "location_admin"
        ? userInfo?.ownLocation?.locationId
        : "",
    items: [
      {
        orderItemId: "",
        lineId: "",
        steelId: "",
        code: "",
        brand: "",
        status: "",
        totalOrderedQty: "",
        totalReceivedQty: "",
        totalOutstandingQty: "",
        forecastedDate: null,
      },
    ],
  };

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5" align="left">
                    Asset type
                  </Typography>
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "5vh", width: "50%" }}
                    animation={"wave"}
                  />
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle1" align="left">
                    Add steel and quantity for each asset:
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Formik
              initialValues={
                editData?._id
                  ? editData
                    ? editData
                    : initialSteelOnOrderData
                  : initialSteelOnOrderData
              }
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const addData = {
                  isPurchaseView: true,
                  callback: handleCallBack,
                  data: {
                    supplier: values?.supplier,
                    orderNumber: values?.orderNumber,
                    locationId: values?.locationId,
                    ...(values?.purchaseDate && {
                      purchaseDate: reformatDates(values?.purchaseDate),
                    }),
                  },
                };
                if (values?.items[0]?.lineId)
                  addData.data.items = values?.items?.map((item: any) => ({
                    lineId: item?.lineId,
                    steelId: item?.steelId,
                    totalOrderedQty: item?.totalOrderedQty,
                    // ...(item?.totalOutstandingQty && {
                    totalOutstandingQty: item?.totalOutstandingQty,
                    // }),
                    // ...(item?.totalReceivedQty && {
                    totalReceivedQty: item?.totalReceivedQty,
                    // }),
                    ...(item?.forecastedDate && {
                      forecastedDate: reformatDates(item?.forecastedDate),
                    }),
                    ...(item?.status && {
                      status: item?.status,
                    }),
                  }));

                if (editData?._id) {
                  const updateData = {
                    callback: handleCallBack,
                    isPurchaseView: true,
                    data: {
                      ...addData.data,
                      steelOrderId: editData?._id,
                    },
                  };
                  updateData.data.items = updateData?.data?.items?.map(
                    (item: any, index: number) => ({
                      ...item,
                      orderItemId: editData?.items[index]?._id ?? uuid4(),
                    })
                  );
                  dispatch(editSteelOnOrder(updateData));
                } else {
                  dispatch(addSteelOnOrder(addData));
                }
              }}
            >
              {({ values, resetForm, isSubmitting, errors, setFieldValue }) => (
                <>
                  <Box
                    ml={0}
                    sx={{
                      p: 3,
                      backgroundColor: "#fff ",
                    }}
                  >
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} md={3}>
                          <Typography sx={{ marginTop: "10px" }} align="right">
                            Purchase Order No.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          pb={2}
                        >
                          <Field name={`orderNumber`}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <FormControl>
                                  <TextField
                                    label="Purchase Order No."
                                    name={`orderNumber`}
                                    id={`orderNumber`}
                                    type="text"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    // value={values?.orderNumber}
                                    value={field.value ?? ""}
                                    onChange={(event: any) => {
                                      handleChange(
                                        event,
                                        setFieldValue,
                                        `orderNumber`,
                                        undefined
                                      );
                                    }}
                                  />
                                  <FormHelperText
                                    error={!!meta.error}
                                    style={errorStyles}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <Typography sx={{ marginTop: "10px" }} align="right">
                            Purchase Date
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          pb={2}
                        >
                          <Field name={`purchaseDate`}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <FormControl>
                                  <LocalizationProvider
                                    dateAdapter={AdapterLuxon}
                                  >
                                    <DatePicker
                                      label="Purchase Date"
                                      format="dd/MM/yyyy"
                                      onChange={(event) => {
                                        handleOnDateChange(
                                          event,
                                          setFieldValue,
                                          `purchaseDate`,
                                          0
                                        );
                                      }}
                                      value={
                                        values?.purchaseDate
                                          ? DateTime.fromJSDate(
                                              new Date(values?.purchaseDate)
                                            )
                                          : null
                                      }
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          fullWidth: true,
                                          id: `purchaseDate`,
                                          name: `purchaseDate`,
                                          // required: true,
                                          sx: {
                                            width: "200px",
                                          },
                                        },
                                        actionBar: {
                                          actions: ["clear"],
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                  <FormHelperText
                                    error={!!meta.error}
                                    style={errorStyles}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} md={3}>
                          <Typography sx={{ marginTop: "10px" }} align="right">
                            Supplier
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          pb={2}
                        >
                          <Field name={`supplier`}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <FormControl>
                                  <TextField
                                    label="Supplier"
                                    name={`supplier`}
                                    id={`supplier`}
                                    type="text"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={field.value ?? ""}
                                    onChange={(event: any) => {
                                      handleChange(
                                        event,
                                        setFieldValue,
                                        `supplier`,
                                        undefined
                                      );
                                    }}
                                  />
                                  <FormHelperText
                                    error={!!meta.error}
                                    style={errorStyles}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <Typography sx={{ marginTop: "10px" }} align="right">
                            Location
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          pb={2}
                        >
                          <Field name={`locationId`}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <FormControl>
                                  <TextField
                                    select
                                    label="Location"
                                    name={`locationId`}
                                    id={`locationId`}
                                    type="text"
                                    required
                                    style={{
                                      width: "200px",
                                    }}
                                    variant="outlined"
                                    size="small"
                                    value={field.value ?? ""}
                                    disabled={
                                      editData?._id ||
                                      userInfo?.jobRole?.key ===
                                        "supply_chain_manager" ||
                                      userInfo?.jobRole?.key ===
                                        "location_admin"
                                    }
                                    fullWidth
                                    onChange={(event: any) =>
                                      handleChange(
                                        event,
                                        setFieldValue,
                                        `locationId`,
                                        0,
                                        values
                                      )
                                    }
                                  >
                                    {filterData?.allowedLocations?.map(
                                      (option: any, i: number) => (
                                        // @ts-ignore
                                        <MenuItem
                                          key={option?._id}
                                          value={option?._id}
                                        >
                                          {titleCase(option?.fullName)}
                                        </MenuItem>
                                      )
                                    )}
                                  </TextField>
                                  <FormHelperText
                                    error={!!meta.error}
                                    style={errorStyles}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid>
                        <FieldArray name="items">
                          {({ push, remove }) => (
                            <React.Fragment>
                              {values?.items?.map((_: any, index: any) => (
                                <div className="add-more-container-a">
                                  <Grid container item key={index} spacing={2}>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field name={`items[${index}].lineId`}>
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Line ID"
                                              name={`items[${index}].lineId`}
                                              id={`items[${index}].lineId`}
                                              type="number"
                                              required
                                              // required={
                                              //   values?.items[index].lineId ||
                                              //   values?.items[index].steelId ||
                                              //   values?.items[index]
                                              //     .totalOrderedQty
                                              // }
                                              size="small"
                                              disabled={
                                                editData?.items?.length > index
                                              }
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `items[${index}].lineId`,
                                                  index
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={values?.items[index]?.steelId}
                                        label="Product Name"
                                        required
                                        // required={
                                        //   values?.items[index].lineId ||
                                        //   values?.items[index].steelId ||
                                        //   values?.items[index].totalOrderedQty
                                        // }
                                        placeholder="Product Name"
                                        className="custom-select"
                                        disabled={
                                          !values?.locationId ||
                                          editData?.items?.length > index
                                        }
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `items[${index}].steelId`,
                                            index
                                          )
                                        }
                                      >
                                        {steelListData?.rows?.map(
                                          (option: any) => (
                                            <MenuItem
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option?.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={values?.items[index]?.code}
                                        placeholder="Code"
                                        label="Code"
                                        disabled={
                                          !values?.locationId ||
                                          editData?.items?.length > index
                                        }
                                        className="custom-select"
                                        isMulti={true}
                                        required
                                        // required={
                                        //   values?.items[index].lineId ||
                                        //   values?.items[index].steelId ||
                                        //   values?.items[index].totalOrderedQty
                                        // }
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `items[${index}].code`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(code).length ? (
                                          // @ts-ignore
                                          code[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.code}
                                                >
                                                  {option?.code}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        // name={`steelUsed[${index}].brand`}
                                        // id={`steelUsed[${index}].brand`}
                                        value={values?.items[index]?.brand}
                                        placeholder="Brand"
                                        disabled={
                                          !values?.locationId ||
                                          editData?.items?.length > index
                                        }
                                        required
                                        // required={
                                        //   values?.items[index].lineId ||
                                        //   values?.items[index].steelId ||
                                        //   values?.items[index].totalOrderedQty
                                        // }
                                        label="Brand"
                                        className="custom-select"
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `items[${index}].brand`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(code).length ? (
                                          // @ts-ignore
                                          code[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.brand}
                                                >
                                                  {option?.brand}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        className="error-class-message"
                                        component="div"
                                        name={`items[${index}].brand`}
                                      />
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        name={`items[${index}].totalOrderedQty`}
                                      >
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Ordered Qty"
                                              name={`items[${index}].totalOrderedQty`}
                                              id={`items[${index}].totalOrderedQty`}
                                              type="number"
                                              required
                                              // required={
                                              //   values?.items[index].lineId ||
                                              //   values?.items[index].steelId ||
                                              //   values?.items[index]
                                              //     .totalOrderedQty
                                              // }
                                              size="small"
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `items[${index}].totalOrderedQty`,
                                                  index,
                                                  values
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        name={`items[${index}].totalReceivedQty`}
                                      >
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Received Qty"
                                              name={`items[${index}].totalReceivedQty`}
                                              id={`items[${index}].totalReceivedQty`}
                                              type="number"
                                              size="small"
                                              required
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `items[${index}].totalReceivedQty`,
                                                  index,
                                                  values
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        name={`items[${index}].totalOutstandingQty`}
                                      >
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Outstanding Qty"
                                              name={`items[${index}].totalOutstandingQty`}
                                              id={`items[${index}].totalOutstandingQty`}
                                              type="number"
                                              size="small"
                                              disabled
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `items[${index}].totalOutstandingQty`,
                                                  index
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                      >
                                        <DatePicker
                                          label="Forecasted Date"
                                          format="dd/MM/yyyy"
                                          disabled={
                                            values?.items[index]
                                              ?.totalReceivedQty < 1
                                          }
                                          onChange={(event) => {
                                            handleOnDateChange(
                                              event,
                                              setFieldValue,
                                              `items[${index}].forecastedDate`,
                                              index
                                            );
                                          }}
                                          minDate={
                                            values?.purchaseDate
                                              ? DateTime.fromJSDate(
                                                  new Date(values?.purchaseDate)
                                                )
                                              : DateTime.fromJSDate(
                                                  new Date(
                                                    moment
                                                      .utc()
                                                      .subtract(3, "months")
                                                      .set("date", 1)
                                                      .format("'YYYY-MM-DD")
                                                  )
                                                )
                                          }
                                          value={
                                            values?.items[index]?.forecastedDate
                                              ? DateTime.fromJSDate(
                                                  new Date(
                                                    values?.items[
                                                      index
                                                    ]?.forecastedDate
                                                  )
                                                )
                                              : null
                                          }
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              fullWidth: true,
                                              id: `items[${index}].forecastedDate`,
                                              name: `items[${index}].forecastedDate`,
                                              disabled:
                                                values?.items[index]
                                                  ?.totalReceivedQty < 1,
                                            },
                                            actionBar: {
                                              actions: ["clear"],
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        // name={`steelUsed[${index}].brand`}
                                        // id={`steelUsed[${index}].brand`}
                                        value={values?.items[index]?.status}
                                        placeholder="Status"
                                        label="Status"
                                        className="custom-select"
                                        disabled
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `items[${index}].status`,
                                            index
                                          )
                                        }
                                      >
                                        {filterData?.status?.map(
                                          (option: any) => (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {titleCase(option)}
                                            </MenuItem>
                                          )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        className="error-class-message"
                                        component="div"
                                        name={`items[${index}].status`}
                                      />
                                    </Grid>
                                    {/* <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                      >
                                        <DatePicker
                                          label="Purchase Date"
                                          format="dd/MM/yyyy"
                                          onChange={(event) =>
                                            handleOnDateChange(
                                              event,
                                              setFieldValue,
                                              `steelUsed[${index}].purchaseDate`,
                                              index
                                            )
                                          }
                                          value={
                                            values?.steelUsed[index]
                                              ?.purchaseDate
                                              ? DateTime.fromJSDate(
                                                  new Date(
                                                    values?.steelUsed[
                                                      index
                                                    ]?.purchaseDate
                                                  )
                                                )
                                              : null
                                          }
                                          maxDate={DateTime.fromJSDate(
                                            new Date(
                                              values?.steelUsed[
                                                index
                                              ]?.deliveryDate
                                            )
                                          )}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              fullWidth: true,
                                              id: `steelUsed[${index}].purchaseDate`,
                                              name: `steelUsed[${index}].purchaseDate`,
                                              required: true,
                                            },
                                            actionBar: {
                                              actions: ["clear"],
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                      >
                                        <DatePicker
                                          label="Expected Delivery Date"
                                          format="dd/MM/yyyy"
                                          onChange={(event) =>
                                            handleOnDateChange(
                                              event,
                                              setFieldValue,
                                              `steelUsed[${index}].deliveryDate`,
                                              index
                                            )
                                          }
                                          value={
                                            values?.steelUsed[index]
                                              ?.deliveryDate
                                              ? DateTime.fromJSDate(
                                                  new Date(
                                                    values?.steelUsed[
                                                      index
                                                    ]?.deliveryDate
                                                  )
                                                )
                                              : null
                                          }
                                          minDate={DateTime.fromJSDate(
                                            new Date(
                                              values?.steelUsed[
                                                index
                                              ]?.purchaseDate
                                            )
                                          )}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              fullWidth: true,
                                              id: `steelUsed[${index}].deliveryDate`,
                                              name: `steelUsed[${index}].deliveryDate`,
                                              required:
                                                values.steelUsed[index]
                                                  .status !== "ON_ORDER",
                                              // required: true,
                                            },
                                            actionBar: {
                                              actions: ["clear"],
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        name={`steelUsed[${index}].outstandingQty`}
                                      >
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Outstanding Quantity"
                                              name={`steelUsed[${index}].outstandingQty`}
                                              id={`steelUsed[${index}].outstandingQty`}
                                              type="number"
                                              size="small"
                                              disabled={
                                                values.steelUsed[index]
                                                  .status !== "BACK_ORDER"
                                              }
                                              required={
                                                values.steelUsed[index]
                                                  .status === "BACK_ORDER"
                                              }
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `steelUsed[${index}].outstandingQty`,
                                                  index
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                      {/* <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        fullWidth
                                        size="small"
                                        id={`steelUsed[${index}].outstandingQty`}
                                        name={`steelUsed[${index}].outstandingQty`}
                                        value={
                                          values.steelUsed[index].outstandingQty
                                        }
                                        component={TextField}
                                        type="text"
                                        disabled={
                                          values.steelUsed[index].status !==
                                          "BACK_ORDER"
                                        }
                                        required={
                                          values.steelUsed[index].status ===
                                          "BACK_ORDER"
                                        }
                                        label="Outstanding Quantity"
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].outstandingQty`,
                                            index
                                          )
                                        }
                                      />
                                    </Grid> */}
                                    {/* </Grid>
                                     */}
                                    <Grid item xs={0.75} sm={0.75} md={0.75}>
                                      <div className="remove-add-more-item">
                                        {!editData._id &&
                                        values?.items.length > 1 ? (
                                          <IconButton
                                            onClick={() => {
                                              remove(index);
                                              deleteAndShift(code, index);
                                            }}
                                          >
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}

                              <Grid item>
                                {typeof errors.items === "string" ? (
                                  <Typography color="error">
                                    {errors.items}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <IconButton
                                  disabled={isSubmitting}
                                  onClick={() => push(emptySteelObject)}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </div>
                            </React.Fragment>
                          )}
                        </FieldArray>
                      </Grid>
                      <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {"Save"}
                        </Button>
                        <Button variant="outlined" onClick={handleClose}>
                          Cancel
                        </Button>
                        {editData?._id ? (
                          <></>
                        ) : (
                          <Button
                            variant="outlined"
                            type="reset"
                            onClick={(e) => resetForm()}
                            color="error"
                          >
                            Reset
                          </Button>
                        )}
                      </Box>
                    </Form>
                  </Box>
                </>
              )}
            </Formik>
          </>
        )}
      </>
    </React.Fragment>
  );
};
