import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { ShortageFilters, ShortageWithoutFilters } from "./Filters";
import { ShortageChart } from "./ShortageChart";
import { TemporaryOrderTray } from "./TemporaryOrderTray";
import { ViewShortage } from "./ViewShortage";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { genericForwardMontsColumn } from "../../utils/genericForwardMontsColumn";
import {
  getEditTempOrder,
  getShortageList,
} from "../../api/shortage/Api.service";
import { getFilterData } from "../../api/steel/Api.service";
import { getTrayTypesListing } from "../../api/tray-types-api/TrayType.service";

const synopsisStyles = {
  display: "inline-block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "150px",
  whiteSpace: "nowrap",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export const ShortageScreen = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);
  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditShortageData({ id: "" });
  };

  const dispatch: AppDispatch = useDispatch();
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { shortageListData } = useSelector((state: any) => state.shortageState);
  const { trays } = useSelector((state: any) => state.trayTypeState);

  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");

  const [isTableView, setIsTableView] = useState(true);
  const [editShortageData, setEditShortageData] = useState({ id: "" });
  const [id, setId] = useState<string>("1");

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [shortageData, setShortageData] = useState({
    name: "",
    grade: "",
    code: [],
    tempOrderId: "",
    // shortageRange: "",
    probability: "75 - 100",
    minShortageDate: "",
    maxShortageDate: "",
    location: masterLocation.locationId,
  });

  useEffect(() => {
    refreshShortage();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    shortageData,
    shortageData.tempOrderId,
    // id,
  ]);
  useEffect(() => {
    if (shortageData?.location && shortageData?.location !== "all")
      dispatch(getFilterData({ locationId: shortageData.location }));
    else dispatch(getFilterData({}));

    dispatch(
      getTrayTypesListing({
        page: "1",
        pageSize: "1000",
        location: "all",
      })
    );
  }, []);

  useEffect(() => {
    setShortageData({
      ...shortageData,
      location: masterLocation.locationId,
    });
  }, [masterLocation]);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const runTempOrder = () => {
    // if (id) {
    dispatch(getEditTempOrder(masterLocation?.locationId)).then((e) => {
      // add delay
      setTimeout(() => {
        setShortageData({ ...shortageData, tempOrderId: e?.payload?._id });
      }, 300);
    });
    // }
  };

  // on mount if there is already a temp order
  useEffect(() => {
    if (id) {
      dispatch(getEditTempOrder(masterLocation?.locationId)).then((e) => {
        if (e?.payload)
          setShortageData({ ...shortageData, tempOrderId: e?.payload?._id });
      });
    }
  }, []);
  const refreshShortage = () => {
    const {
      name,
      grade,
      code,
      tempOrderId,
      // shortageRange,
      minShortageDate,
      maxShortageDate,
      location,
      probability,
    } = shortageData;
    const searchData = {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      name,
      grade,
      code,
      tempOrderId,
      // shortageRange,
      minShortageDate,
      maxShortageDate,
      location,
      probability,
    };
    if (Boolean(minShortageDate) !== Boolean(maxShortageDate)) return;
    dispatch(getShortageList(searchData));
    if (shortageData?.location && shortageData?.location !== "all")
      dispatch(getFilterData({ locationId: shortageData.location }));
    else dispatch(getFilterData({}));
  };
  const renderClickablecell = (params: any) => {
    const row = params.row.original;
    const field = params.column.id;

    const isClickEnable =
      shortageData.location !== "all" && params.cell.getValue() <= 0
        ? true
        : false;
    const data = {
      id: row?._id,
      fieldName: field,
      name: row?.name,
      value: params.cell.getValue(),
    };

    const showData = () => {
      setEditShortageData(data);
      setAddOpen(true);
    };

    if (field === "name" || field === "code") {
      return (
        <span
          // @ts-ignore
          style={{ ...synopsisStyles, maxWidth: "unset" }}
          title={params.cell.getValue()}
        >
          {params.cell.getValue()}
        </span>
      );
    }
    return (
      <>
        {isClickEnable ? (
          <span
            className={
              params.cell.getValue() < 0
                ? "red1 clickableText"
                : "yellow2 clickableText"
            }
            // @ts-ignore
            style={{ ...synopsisStyles, maxWidth: "55px" }}
            title={params.cell.getValue()}
            onClick={showData}
          >
            {params.cell.getValue()}
          </span>
        ) : (
          <span
            // @ts-ignore
            style={{ ...synopsisStyles, maxWidth: "55px" }}
            className={params.cell.getValue() < row?.minQty ? "yellow1" : ""}
            title={params.cell.getValue()}
          >
            {params.cell.getValue()}
          </span>
        )}
      </>
    );
  };

  const columns = [
    {
      accessorKey: "code",
      header: "CODE",
      size: 150,
      Cell: (params: any) => renderClickablecell(params),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "name",
      header: "PRODUCT NAME",
      size: 250,
      Cell: (params: any) => renderClickablecell(params),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    ...genericForwardMontsColumn({
      monthIndex: new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1,
      customRender: true,
      customRenderFn: (params: any) => renderClickablecell(params),
    }),
  ];

  console.log("ASDAS", columns);

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Forecast Demand"
            isAddBtn={false}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            // width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          {isTableView ? (
            <>
              <Box>
                <div
                  style={{
                    height: isMobileView ? "100%" : "80vh",
                    // width: "100%",
                    backgroundColor: "white",
                    borderRadius: "6px",
                    padding: "16px",
                    boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
                    paddingTop: 0,
                  }}
                >
                  <TemporaryOrderTray
                    id={id}
                    setId={setId}
                    trays={trays}
                    refreshShortage={refreshShortage}
                    runTempOrder={runTempOrder}
                  />
                  <Box
                    sx={{
                      paddingBottom: 0,
                      paddingTop: "16px",
                    }}
                  >
                    <ShortageFilters
                      steelData={shortageData}
                      setSteelData={setShortageData}
                      isTableView={isTableView}
                      setIsTableView={setIsTableView}
                    />
                  </Box>
                  <MaterialReactTable
                    data={
                      shortageListData?.shortageArray?.length > 0
                        ? shortageListData?.shortageArray
                        : []
                    }
                    columns={columns}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableGlobalFilter={false}
                    enableDensityToggle={false}
                    enableTopToolbar={false}
                    enableFullScreenToggle={false}
                    enableToolbarInternalActions={false}
                    enableStickyHeader
                    enablePinning
                    initialState={{
                      columnPinning: { left: ["code", "name"] },
                    }}
                    manualPagination
                    onPaginationChange={setPagination}
                    rowCount={shortageListData?.totalCount}
                    state={{ pagination }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [],
                    }}
                    muiTableProps={{
                      sx: {
                        border: "1px solid #e0e0e07a",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        backgroundColor: "rgb(245, 246, 250)",
                        fontWeight: "600",
                        borderRight: "1px solid #e0e0e07a",
                        boxShadow: 0,
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        borderRight: "1px solid #e0e0e07a",
                        boxShadow: 0,
                      },
                    }}
                  />
                </div>
              </Box>
            </>
          ) : (
            <Box>
              <div
                style={{
                  height: isMobileView ? "100%" : "80vh",
                  // width: "100%",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  padding: "16px",
                  boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
                }}
              >
                <ShortageWithoutFilters
                  isTableView={isTableView}
                  setIsTableView={setIsTableView}
                />
                <ShortageChart />
              </div>
            </Box>
          )}
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <ViewShortage
        open={addOpen}
        handleClose={handleAddClose}
        shortageData={editShortageData}
        title={"Forecast Demand Replacement view"}
      />
    </Box>
  );
};
