import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Container,
  Modal,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { AddSteelOnOrder } from "./AddSteelOnOrder";
import { SteelOnOrderFilters } from "./Filters";
import { SteelOnOrderTable } from "./SteelOnOrderTable";
import { clearEditData, clearListData } from "./slice/SteelOnOrderSlice";
import {
  getEditSteelOnOrder,
  getFilterData,
  getSteelOnOrderList,
} from "../../api/steelonorder/Api.service";
import { getSteelList } from "../../api/steel/Api.service";
import { checkPermission } from "../../utils/checkPermission";
import { reformatDates } from "../../utils/reformatDates";
import { AddShipment } from "./AddShipment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
export const SteelOnOrderMain = (): JSX.Element => {
  const pageHeader = "Steel on Order";

  const [addOpen, setAddOpen] = useState({
    purchaseOrder: false,
    shipment: false,
  });
  const dispatch: AppDispatch = useDispatch();

  const handleClickOpen = () => {
    setAddOpen({
      purchaseOrder: isPurchaseView,
      shipment: !isPurchaseView,
    });
  };

  const handleAddClose = () => {
    setAddOpen({
      purchaseOrder: false,
      shipment: false,
    });
    setEditSteelOnOrderData({ _id: "" });
    dispatch(clearEditData());
    refreshSteelOnOrder();
  };

  const { steelOnOrderListData } = useSelector(
    (state: any) => state.steelOnOrderState
  );

  const { steelListData } = useSelector((state: any) => state.steelState);

  const { isMobileView, masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const [open, setOpen] = useState(false);
  const [isPurchaseView, setIsPurchaseView] = useState(true);
  const [deletId, setDeletId] = useState("");

  const [editSteelOnOrderData, setEditSteelOnOrderData] = useState({
    _id: "",
  });
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [steelOnOrderFiltersData, setSteelOnOrderFiltersData] = useState({
    purchaseOrderNumber: "",
    supplier: "",
    status: "",
    steelName: "",
    code: [],
    brand: "",
    purchaseStartDate: "",
    purchaseEndDate: "",
    deliveryStartDate: "",
    deliveryEndDate: "",
    shipmentType: "",
    location: masterLocation.locationId,
  });

  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo,
      "STEEL_ON_ORDER"
    );
    return getPermission;
  });

  useEffect(() => {
    dispatch(clearListData());
    refreshSteelOnOrder();
  }, [steelOnOrderFiltersData, pageState, isPurchaseView]);

  useEffect(() => {
    setSteelOnOrderFiltersData({
      ...steelOnOrderFiltersData,
      location: masterLocation.locationId,
    });
    setPermission(() => {
      const getPermission = checkPermission(
        masterLocation?.locationId,
        userInfo,
        "STEEL_ON_ORDER"
      );
      return getPermission;
    });
  }, [masterLocation]);

  useEffect(() => {
    dispatch(
      getSteelList({
        page: 1,
        pageSize: 1000,
        location: "all",
      })
    );
    if (steelOnOrderFiltersData?.location !== "all")
      dispatch(
        getFilterData({
          locationId: steelOnOrderFiltersData.location,
          isShipment: !isPurchaseView,
        })
      );
    else dispatch(getFilterData({ isShipment: !isPurchaseView }));
  }, []);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const onEdit = (id: any, row: any) => {
    let tempRow;
    if (isPurchaseView) {
      tempRow = {
        _id: row?._id,
        // @ts-ignore
        orderNumber: row?.purchaseOrder,
        supplier: row?.supplier,
        locationId: row?.locationId,
        purchaseDate: row?.purchaseDate,
        items: row?.steelorderitems,
      };
    } else {
      tempRow = {
        _id: row?._id,
        orderNumber: row?._id,
        supplier: row?.supplier,
        name: row?.name,
        shipmentType: row?.shipmentType,
        locationId: row?.locationId,
        deliveryDate: row?.deliveryDate,
        items: row?.shipmentitems,
      };
    }
    // @ts-ignore
    setEditSteelOnOrderData(tempRow);
    handleClickOpen();
  };

  const refreshSteelOnOrder = () => {
    const {
      purchaseOrderNumber,
      supplier,
      status,
      steelName,
      code,
      brand,
      purchaseStartDate,
      purchaseEndDate,
      deliveryStartDate,
      deliveryEndDate,
      shipmentType,
      location,
    } = steelOnOrderFiltersData;

    const searchData = {
      orderNumber: purchaseOrderNumber,
      supplier,
      status,
      steelName,
      code,
      shipmentType,
      brand,
      purchaseStartDate,
      purchaseEndDate,
      page: pageState.page,
      pageSize: pageState.pageSize,
      deliveryStartDate,
      deliveryEndDate,
      location,
      isPurchaseView,
    };

    if (Boolean(purchaseStartDate) !== Boolean(purchaseEndDate)) return;
    if (Boolean(deliveryStartDate) !== Boolean(deliveryEndDate)) return;

    dispatch(getSteelOnOrderList(searchData));

    if (steelOnOrderFiltersData?.location !== "all")
      dispatch(
        getFilterData({
          locationId: steelOnOrderFiltersData.location,
          isShipment: !isPurchaseView,
        })
      );
    else dispatch(getFilterData({ isShipment: !isPurchaseView }));
  };

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName={`Steel on Order - ${
              isPurchaseView ? "Purchase Orders" : "Shipments"
            }`}
            addBtnTitle={`Add ${
              isPurchaseView ? "Purchase Order" : "Shipment"
            }`}
            disabled={!permission}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
            border: "1px solid #e0e0e07a",
          }}
        >
          <div
            style={{
              padding: "16px",
              height: "calc(100% - 16px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SteelOnOrderFilters
              componentName={"SteelOnOrder"}
              steelOnOrderFiltersData={steelOnOrderFiltersData}
              setSteelOnOrderFiltersData={setSteelOnOrderFiltersData}
              setPermission={setPermission}
              isPurchaseView={isPurchaseView}
              setIsPurchaseView={setIsPurchaseView}
            />
            <SteelOnOrderTable
              steelOnOrderListData={steelOnOrderListData?.rows}
              steelOnOrderFiltersData={steelOnOrderFiltersData}
              refreshSteelOnOrder={refreshSteelOnOrder}
              onEdit={onEdit}
              steelListData={steelListData?.rows}
              permission={permission}
              page={pageHeader}
              isPurchaseView={isPurchaseView}
            />
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={steelOnOrderListData?.total}
              page={pageState.page - 1}
              onPageChange={(
                event: React.MouseEvent<HTMLButtonElement> | null,
                newPage: number
              ) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              rowsPerPage={pageState.pageSize}
              sx={{
                marginTop: "auto",
                marginBottom: "16px",
                borderTop: "1px solid rgba(224, 224, 224, 1)",
                minHeight: "52px",
              }}
            />
          </div>
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddSteelOnOrder
        open={addOpen.purchaseOrder}
        handleClose={handleAddClose}
        editData={editSteelOnOrderData}
        title={
          editSteelOnOrderData?._id
            ? "Edit Purchase Order"
            : "Add Purchase Order"
        }
        refreshSteelOnOrder={refreshSteelOnOrder}
        steelListData={steelListData?.rows}
      />
      <AddShipment
        open={addOpen.shipment}
        handleClose={handleAddClose}
        editData={editSteelOnOrderData}
        title={editSteelOnOrderData?._id ? "Edit Shipment" : "Add Shipment"}
        refreshSteelOnOrder={refreshSteelOnOrder}
        steelListData={steelListData?.rows}
      />
    </Box>
  );
};
