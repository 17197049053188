import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

export const getSteelOnOrderList = createAsyncThunk(
  "GET_STEEL_ON_ORDER_LIST",
  async (postData: any, thunkApi) => {
    let {
      page,
      pageSize,
      orderNumber,
      supplier,
      status,
      steelName,
      code,
      brand,
      purchaseStartDate,
      purchaseEndDate,
      deliveryStartDate,
      deliveryEndDate,
      isPurchaseView,
      shipmentType,
      location,
    } = postData;

    purchaseStartDate = reformatDates(purchaseStartDate);
    purchaseEndDate = reformatDates(purchaseEndDate);
    deliveryStartDate = reformatDates(deliveryStartDate);
    deliveryEndDate = reformatDates(deliveryEndDate);

    const { signal, dispatch } = thunkApi;
    try {
      const codeUrl = generateCodeUrl(code);
      const url = `steelOrder/${isPurchaseView ? `po` : `shipment`}/list?${
        page ? `page=${page}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${codeUrl ?? ""}${
        orderNumber ? `orderNumber=${orderNumber}&` : ""
      }${isPurchaseView && status ? `status=${status}&` : ""}${
        brand ? `brand=${brand}&` : ""
      }${steelName ? `name=${steelName}&` : ""}${
        supplier ? `supplier=${supplier}&` : ""
      }${purchaseStartDate ? `purchaseStartDate=${purchaseStartDate}&` : ""}${
        purchaseEndDate ? `purchaseEndDate=${purchaseEndDate}&` : ""
      }${
        !isPurchaseView && shipmentType ? `shipmentType=${shipmentType}&` : ""
      }${
        !isPurchaseView && deliveryStartDate
          ? `deliveryStartDate=${deliveryStartDate}&`
          : ""
      }${
        !isPurchaseView && deliveryEndDate
          ? `deliveryEndDate=${deliveryEndDate}&`
          : ""
      }${location !== "all" ? `locationId=${location}&` : ""}`;

      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const t = response?.data?.data?.rows?.map((data: any) => {
        const temp = data[
          `${isPurchaseView ? `steelorderitems` : `shipmentitems`}`
        ].map((d: any) => ({
          ...d,
          ...(isPurchaseView && {
            forecastedDate: d?.forecastedDate,
          }),
          name: d?.steel.name,
          code: d?.steel.code,
          brand: d?.steel.brand,
          grade: d?.steel.grade,
        }));
        return {
          ...data,
          locationName: data?.location?.name,
          purchaseDate: data?.purchaseDate,
          ...(!isPurchaseView && {
            deliveryDate: data?.deliveryDate,
            purchaseOrder: data?.steelorder?.purchaseOrder,
            supplier: data?.steelorder?.supplier,
            purchaseDate: data?.steelorder?.purchaseDate,
          }),
          [`${isPurchaseView ? `steelorderitems` : `shipmentitems`}`]: temp,
        };
      });
      const finalData = {
        ...response?.data?.data,
        rows: t,
      };
      return finalData;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching orders!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addSteelOnOrder = createAsyncThunk(
  "ADD_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        `steelOrder/${postData.isPurchaseView ? `po` : `shipment`}/add`,
        postData?.data,
        {
          signal,
        }
      );
      if (response) {
        postData?.callback();
        const data = response?.data;
        if (data?.status === 0)
          dispatch(toggleSnacker(errorOpen(data?.message)));
        else
          dispatch(
            toggleSnacker(
              successOpen("Steel on order has been created successfully!")
            )
          );
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while creating new steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteSteelOnOrder = createAsyncThunk(
  "DELETE_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `steelOrder/${postData?.isPurchaseView ? `po` : `shipment`}/delete?${
          postData?.isPurchaseView ? `steelOrderId` : `shipmentId`
        }=${postData?.id}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response?.data;
      if (
        data?.status === 0 &&
        data?.message ===
          "Failed to delete Steel order! Shipment already received for the steel order."
      ) {
        dispatch(toggleSnacker(errorOpen(data?.message)));
      } else
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Steel on order has been deleted successfully!"
            )
          )
        );
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while deleting the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteSteelOnOrderItem = createAsyncThunk(
  "DELETE_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `steelOrder/${
          postData?.isPurchaseView ? `po` : `shipment`
        }/item/delete?${
          postData?.isPurchaseView ? `orderItemId` : `shipmentItemId`
        }=${postData?.id}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response?.data;
      if (
        data?.status === 0 &&
        data?.message ===
          "Cannot modify or delete the steel order item. A shipment item is already received for the order item."
      ) {
        dispatch(toggleSnacker(errorOpen(data?.message)));
      } else
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Order item has been deleted successfully!"
            )
          )
        );
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while deleting the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const editSteelOnOrder = createAsyncThunk(
  "EDIT_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        `steelOrder/${postData.isPurchaseView ? "po" : "shipment"}/edit`,
        postData?.data,
        {
          signal,
        }
      );
      if (response) {
        postData?.callback();
        const data = response?.data;
        if (data.status === 0)
          dispatch(toggleSnacker(errorOpen(data?.message)));
        else
          dispatch(
            toggleSnacker(
              successOpen("Steel on order has been successfully updated!")
            )
          );
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while updating the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditSteelOnOrder = createAsyncThunk(
  "GET_EDIT_STEEL_ON_ORDER",
  async (params: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      // TODO: change trayId
      const response = await API.get(
        `steelOrder/?orderId=${params.id}&locationId=${params.locationId}`,
        {
          signal,
        }
      );
      if (response) {
        const data = response?.data?.data;
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterData = createAsyncThunk(
  "GET_DROPDOWN_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steelOrder/po/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, postData, { signal });
      return response?.data?.data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching steel on order!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
