import {
  Button,
  Card,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import theme from "../../Themes";
import titleCase from "../../utils/titleCase";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { checkPermission } from "../../utils/checkPermission";
export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: any) => {
  const { isMobileView, masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { filterData } = useSelector(
    (state: RootState) => state.steelPerTrayState
  );
  const { setSteelPerTrayFiltersData, steelPerTrayFiltersData, setPermission } =
    props;

  const updateSteelData = (value: string | number, name: any, event?: any) => {
    if (name === "startDate" || name === "endDate") {
      if (event) value = event.toISODate();
      else value = "";
    }
    if (name === "location") {
      setPermission(() => {
        const getPermission = checkPermission(
          value as string,
          userInfo,
          "STEEL_PER_ASSET"
        );
        return getPermission;
      });
    }
    setSteelPerTrayFiltersData({
      ...steelPerTrayFiltersData,
      [name]: value !== "All" ? value : "",
    });
  };

  const clearAllFilters = () => {
    setSteelPerTrayFiltersData({
      grade: "",
      thickness: "",
      width: "",
      length: "",
      trayNames: "",
      code: "",
      location: masterLocation?.locationId,
      configNames: "",
      startDate: "",
      endDate: "",
    });
  };
  if (filterData?.allowedLocations?.length) {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={2}
      >
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="configNames"
              label={`Configuration Name`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.configNames}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.configNames?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="code"
              label={`Code`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.code}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.code?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="trayNames"
              label={`Asset`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.trayNames}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.assetNames?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              // component={TextField}
              type="text"
              name="grade"
              label={`Grade`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.grade}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.grades?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="thickness"
              label={`Thickness`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.thickness}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.thickness?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="length"
              label={`Length`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.length}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.length?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="width"
              label={`Width`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.width}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {" "}
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.width?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Start Date"
              format="dd/MM/yyyy"
              value={
                steelPerTrayFiltersData?.startDate
                  ? DateTime.fromJSDate(
                      new Date(steelPerTrayFiltersData?.startDate)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "startDate",
                  name: "startDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(e: any) => updateSteelData("", "startDate", e)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="End Date"
              format="dd/MM/yyyy"
              value={
                steelPerTrayFiltersData?.endDate
                  ? DateTime.fromJSDate(
                      new Date(steelPerTrayFiltersData?.endDate)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "endDate",
                  name: "endDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(e: any) => updateSteelData("", "endDate", e)}
            />
          </LocalizationProvider>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="location"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={steelPerTrayFiltersData?.location}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.allowedLocations?.map((option: any, i: number) => (
                <MenuItem key={i} value={option._id}>
                  {titleCase(option?.fullName)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const SteelPerTrayFilters = (props: any) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
