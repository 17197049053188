import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState, store } from "../../store/store";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MainScreenHeader from "../../component/pageHeader";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
import { AddScheduleOrder } from "./AddScheduleOrder";
import { ScheduleFilters } from "./Filters";
import { ScheduleFilters2 } from "./ScheduleFilters";
import { forwardMonthsColumns } from "../../utils/forwardMonthsColumns";
import { clearConfigData, clearEditData } from "./slice/ScheduleSlice";
import {
  getScheduleList,
  getFilterData,
  getEditSchedule,
  deleteSchedule,
  getSummaryList,
  getConfigSchedule,
} from "../../api/schedule/Api.service";
import { getTrayTypesListing } from "../../api/tray-types-api/TrayType.service";
import { getMonthsSchedule } from "../../utils/generateMonthsSchedule";
import { checkPermission } from "../../utils/checkPermission";
import { ViewConfig } from "./ViewConfig";
import titleCase from "../../utils/titleCase";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export const ScheduleOrderMain = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const schedulePage = "Schedule";
  const summaryschedulePage = "Schedule Summary";

  let { state } = useLocation();

  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditScheduleData({ trayId: "" });
    dispatch(clearEditData());
  };

  const handleConfigClose = () => {
    setOpenConfig(false);
    dispatch(clearConfigData());
  };
  // test
  const { selectedFilters } = useSelector(
    (state: RootState) => state.TotalRState
  );
  const { scheduleListData, scheduleSummaryData, configSchedule } = useSelector(
    (state: any) => state.scheduleState
  );
  const { trays } = useSelector((state: any) => state.trayTypeState);

  const { userInfo, isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const [open, setOpen] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [deletId, setDeletId] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isTableView, setIsTableView] = useState(true);
  const [editScheduleData, setEditScheduleData] = useState({ trayId: "" });
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [pageStateSummary, setPageSummaryState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [scheduleData, setScheduleData] = useState({
    trayName: [],
    probability:
      selectedFilters.probability !== ""
        ? selectedFilters.probability
        : "75 - 100",
    customerName: "",
    deliveryStartDate: "",
    deliveryEndDate: "",
    processingStartDate: "",
    processingEndDate: "",
    location:
      selectedFilters.location !== ""
        ? selectedFilters.location
        : masterLocation?.locationId,
  });
  const [summaryDataFilters, setSummaryDataFilters] = useState({
    trayName1: [],
    probability1: "75 - 100",
    customerName1: "",
    deliveryStartDate1: "",
    deliveryEndDate1: "",
    location1: masterLocation?.locationId,
  });
  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo,
      "SCHEDULE"
    );
    return getPermission;
  });

  useEffect(() => {
    setScheduleData({
      ...scheduleData,
      location: masterLocation?.locationId,
    });
    setSummaryDataFilters({
      ...summaryDataFilters,
      location1: masterLocation?.locationId,
    });
    setPermission(() => {
      const getPermission = checkPermission(
        masterLocation?.locationId,
        userInfo,
        "SCHEDULE"
      );
      return getPermission;
    });
  }, [masterLocation]);

  useEffect(() => {
    refreshSchedule();
  }, [pageState, pageStateSummary, summaryDataFilters, scheduleData]);

  useEffect(() => {
    dispatch(
      getFilterData({
        locationId: masterLocation.locationId,
      })
    );
    dispatch(
      getTrayTypesListing({
        page: "1",
        pageSize: "1000",
        location: "all",
      })
    );
  }, []);

  useEffect(() => {
    if (!state?.params) return;
    const [processingStartDate, processingEndDate] = getMonthsSchedule(
      state?.params
    );
    // TODO: update location coming from total R
    setScheduleData({
      ...scheduleData,
      processingStartDate: processingStartDate,
      processingEndDate: processingEndDate,
    });
  }, [state?.params]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId({});
  };

  const refreshSchedule = () => {
    // filters
    const {
      trayName,
      customerName,
      probability,
      deliveryStartDate,
      deliveryEndDate,
      processingStartDate,
      processingEndDate,
      location,
    } = scheduleData;
    const {
      trayName1,
      customerName1,
      probability1,
      deliveryStartDate1,
      deliveryEndDate1,
      location1,
    } = summaryDataFilters;
    const searchData = {
      page: pageState.page,
      pageSize: pageState.pageSize,
      trayName: trayName,
      customerName: customerName,
      probability: probability,
      deliveryStartDate: deliveryStartDate,
      deliveryEndDate: deliveryEndDate,
      processingStartDate,
      processingEndDate,
      location,
    };
    const searchData2 = {
      page: pageStateSummary.page,
      pageSize: pageStateSummary.pageSize,
      trayName: trayName1,
      customerName: customerName1,
      probability: probability1,
      deliveryStartDate: deliveryStartDate1,
      deliveryEndDate: deliveryEndDate1,
      location: location1,
    };
    if (
      Boolean(processingStartDate) !== Boolean(processingEndDate) ||
      Boolean(deliveryStartDate) !== Boolean(deliveryEndDate) ||
      Boolean(deliveryStartDate1) !== Boolean(deliveryEndDate1)
    )
      return;

    if (isTableView) {
      if (scheduleData?.location !== "all")
        dispatch(
          getFilterData({
            locationId: scheduleData?.location,
          })
        );
      else dispatch(getFilterData({}));
      dispatch(getScheduleList(searchData));
    } else {
      if (summaryDataFilters?.location1 !== "all")
        dispatch(
          getFilterData({
            locationId: summaryDataFilters?.location1,
          })
        );
      else dispatch(getFilterData({}));
      dispatch(getSummaryList(searchData2));
    }
  };

  const columns: GridColDef[] = [
    {
      field: "kdNumber",
      headerName: "KD NUMBER",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "trayName",
      headerName: "ASSET NAME",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customerName",
      headerName: "CUSTOMER NAME",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "probability",
      headerName: "PROBABILITY %",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: "QTY.",
      width: 60,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "purchaseDate",
      headerName: "PURCHASE DATE",
      width: 190,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "deliveryDate",
      width: 190,
      align: "center",
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <strong style={{ fontWeight: "600", marginRight: "2.5px" }}>
            {"DELIVERY DATE"}
          </strong>
          <Tooltip title="SLS RTS EOL Plan">
            <InfoOutlinedIcon color="info"></InfoOutlinedIcon>
          </Tooltip>
        </>
      ),
    },
    {
      field: "processingDate",
      width: 190,
      align: "center",
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <strong style={{ fontWeight: "600", marginRight: "2.5px" }}>
            {"PROCESSING DATE"}
          </strong>
          <Tooltip title="SLS MAT Process SOL Plan">
            <InfoOutlinedIcon color="info"></InfoOutlinedIcon>
          </Tooltip>
        </>
      ),
    },
    {
      field: "location",
      headerName: "LOCATION",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const text = params.formattedValue;
        return <span>{titleCase(text.fullName)}</span>;
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 280,
      sortable: false,
      align: "center",
      headerAlign: "center",
      // disableClickEventBubbling: false,

      renderCell: (params: any) => {
        const { status, is_form_filled, _id, locationId } = params?.row;
        const handleClick = (e: any) => {
          setOpenConfig(true);
          dispatch(getConfigSchedule(_id));
        };

        const onClick = (e: any) => {
          const currentRow = params.row;
          setDeletId(currentRow);
          handleOpen();
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;

          const temp = {
            ...currentRow,
            deliveryDate: currentRow?.deliveryDate,
            purchaseDate: currentRow?.purchaseDate,
          };
          setEditScheduleData(temp);

          dispatch(
            getEditSchedule({
              id: currentRow._id,
              locationId: masterLocation?.locationId,
            })
          ).then(() => {
            setAddOpen(true);
          });
        };

        if (
          userInfo?.jobRole?.key === "planning_manager" ||
          userInfo?.jobRole?.key === "location_admin"
        ) {
          return (
            <Stack direction="row" spacing={2} alignItems="center">
              <Button variant="contained" onClick={handleClick}>
                View Config
              </Button>
              <Tooltip title="Edit">
                <IconButton
                  disabled={
                    !permission ||
                    locationId !== userInfo?.ownLocation?.locationId
                  }
                >
                  <EditIcon
                    color={
                      !permission ||
                      locationId !== userInfo?.ownLocation?.locationId
                        ? "disabled"
                        : "error"
                    }
                    onClick={onEdit}
                  ></EditIcon>
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <IconButton disabled={!permission}>
                  <DeleteForeverIcon
                    color={
                      !permission ||
                      locationId !== userInfo?.ownLocation?.locationId
                        ? "disabled"
                        : "error"
                    }
                    onClick={onClick}
                  ></DeleteForeverIcon>
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Button variant="contained" onClick={handleClick}>
              View Config
            </Button>
            <Tooltip title="Edit">
              <IconButton disabled={!permission}>
                <EditIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onEdit}
                ></EditIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton disabled={!permission}>
                <DeleteForeverIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onClick}
                ></DeleteForeverIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const summaryColumns: GridColDef[] = forwardMonthsColumns(
    new Date().getMonth()
  );

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName={isTableView ? schedulePage : summaryschedulePage}
            addBtnTitle="Add Order"
            disabled={!permission}
            helperLabel={
              isTableView ? "" : "Based on the SLS RTS EOL Plan (Delivery Date)"
            }
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          {isTableView ? (
            <Box>
              <div
                style={{
                  height: isMobileView ? "100%" : "80vh",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
                }}
              >
                <DataGrid
                  sx={{
                    padding: "16px",
                    borderColor: "#e0e0e07a",
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                      outline: "none !important",
                    },
                    ".MuiDataGrid-columnHeader": {
                      position: "unset",
                    },
                    "& .MuiDataGrid-cell a": {
                      color: "primary.main",
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      borderLeft: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#f5f6fa",
                      borderTop: "2px solid #e0e0e07a",
                      borderRight: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "600 !important",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      borderLeft: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-cell": {
                      borderRight: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      border: "0",
                    },
                    "& .MuiDataGrid-columnHeadersInner": {
                      borderBottom: "1px solid #e0e0e07a !important",
                    },
                    "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                      {
                        display: "none",
                      },
                    "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                      border: 0,
                      background: "white",
                    },
                  }}
                  rows={
                    scheduleListData?.total > 0 ? scheduleListData?.rows : []
                  }
                  columns={columns}
                  density={"standard"}
                  disableColumnMenu={true}
                  components={{
                    LoadingOverlay: SkeletonLoadingOverlay,
                    Toolbar: ScheduleFilters,
                    NoRowsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        No Records Available
                      </Stack>
                    ),
                  }}
                  disableSelectionOnClick={true}
                  rowCount={scheduleListData?.total}
                  rowsPerPageOptions={[]}
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPageState((old) => ({ ...old, page: newPage + 1 }));
                  }}
                  componentsProps={{
                    toolbar: {
                      searchText: searchText,
                      setSearchText: setSearchText,
                      componentName: "STEEL",
                      isTableView: isTableView,
                      setIsTableView: setIsTableView,
                      scheduleData: scheduleData,
                      setScheduleData: setScheduleData,
                      refreshSchedule: refreshSchedule,
                      summaryDataFilters: summaryDataFilters,
                      setSummaryDataFilters: setSummaryDataFilters,
                      setPermission: setPermission,
                    },
                  }}
                />
              </div>
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  height: isMobileView ? "100%" : "80vh",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
                }}
              >
                <DataGrid
                  sx={{
                    padding: "16px",
                    borderColor: "#e0e0e07a",
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                      outline: "none !important",
                    },
                    ".MuiDataGrid-columnHeader": {
                      position: "unset",
                    },
                    "& .MuiDataGrid-cell a": {
                      color: "primary.main",
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      borderLeft: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#f5f6fa",
                      borderTop: "2px solid #e0e0e07a",
                      borderRight: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "600 !important",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      borderLeft: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-cell": {
                      borderRight: "1px solid #e0e0e07a",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      border: "0",
                    },
                    "& .MuiDataGrid-columnHeadersInner": {
                      borderBottom: "1px solid #e0e0e07a !important",
                    },
                    "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                      {
                        display: "none",
                      },
                    "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                      border: 0,
                      background: "white",
                    },
                  }}
                  rows={
                    scheduleSummaryData?.total > 0
                      ? scheduleSummaryData?.rows
                      : []
                  }
                  columns={summaryColumns}
                  density={"standard"}
                  disableColumnMenu={true}
                  components={{
                    LoadingOverlay: SkeletonLoadingOverlay,
                    Toolbar: ScheduleFilters2,
                    NoRowsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        No Records Available
                      </Stack>
                    ),
                  }}
                  disableSelectionOnClick={true}
                  rowCount={scheduleSummaryData?.total}
                  getRowId={(row) => row?.trayName}
                  rowsPerPageOptions={[]}
                  page={pageStateSummary.page - 1}
                  pageSize={pageStateSummary.pageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPageSummaryState((old) => ({
                      ...old,
                      page: newPage + 1,
                    }));
                  }}
                  componentsProps={{
                    toolbar: {
                      searchText: searchText,
                      setSearchText: setSearchText,
                      componentName: "ScheduleSummary",
                      isTableView: isTableView,
                      setIsTableView: setIsTableView,
                      scheduleData: scheduleData,
                      setScheduleData: setScheduleData,
                      summaryDataFilters: summaryDataFilters,
                      setSummaryDataFilters: setSummaryDataFilters,
                      refreshSchedule: refreshSchedule,
                      setPermission: setPermission,
                    },
                  }}
                />
              </div>
            </Box>
          )}
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Deleting this will impact the calculations. Are you sure you want
              to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteSchedule({
                      // @ts-ignore
                      trayOrderId: deletId._id,
                      // @ts-ignore
                      locationId: deletId.locationId,
                      callback: refreshSchedule,
                    })
                  ).then(() => {
                    handleClose();
                  });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddScheduleOrder
        open={addOpen}
        handleClose={handleAddClose}
        editData={editScheduleData}
        title={editScheduleData?.trayId ? "Edit order" : "Add new order"}
        refreshSchedule={refreshSchedule}
        traysListData={trays}
      />
      <ViewConfig
        open={openConfig}
        handleClose={handleConfigClose}
        setOpenConfig={setOpenConfig}
        title={"View Configuration"}
        configSchedule={configSchedule}
      />
    </Box>
  );
};
