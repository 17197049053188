import { PopUpMain } from "../../component/popUp";
import {
  Box,
  Button,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  addSteelPerTray,
  editSteelPerTray,
} from "../../api/steelpertray/Api.service";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AppDispatch, RootState } from "../../store/store";
import { deleteAndShift } from "../../utils/deleteAndShift";
import { VirtualizeCode } from "./Virtualize";
import titleCase from "../../utils/titleCase";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { getTrayTypesListing } from "../../api/tray-types-api/TrayType.service";
import { clearTrayData } from "../trayTypes/slice/TrayTypesSlice";
import { getSteelList } from "../../api/steel/Api.service";
import { clearSteelData } from "../steel/slice/SteelSlice";

export const AddSteelPerTray = (props: any): JSX.Element => {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"lg"}>
        <Box>
          <AddSteelPerTrayScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
};

export const AddSteelPerTrayScreen = (props: any): JSX.Element => {
  const emptySteelObject = {
    steelId: "",
    code: "",
    width: "",
    length: "",
    quantity: "",
  };

  const { handleClose, editData, refreshSteelPerTray } = props;

  const dispatch: AppDispatch = useDispatch();

  const { filterData } = useSelector(
    (state: RootState) => state.steelPerTrayState
  );

  const { steelListData } = useSelector((state: any) => state.steelState);

  const { trays } = useSelector((state: any) => state.trayTypeState);

  const { userInfo } = useSelector((state: RootState) => state.globalAppState);

  const [product, setProduct] = React.useState({});
  const [value, setValue] = React.useState<any>({}); //selected
  const [inputValue, setInputValue] = React.useState<any>({}); //typed

  const handleOnDateChange = (
    event: DateTime | null,
    fieldName: any,
    Name: any
  ) => {
    if (Name === "effectiveStartDate" || Name === "effectiveEndDate") {
      if (event) {
        const tempDate = event.toISODate();
        fieldName(Name, tempDate);
      } else {
        fieldName(Name, "");
      }
    }
  };

  const handleChange = (
    event: SelectChangeEvent<string>,
    fieldName: any,
    Name: any,
    index: any,
    values?: any
  ) => {
    let {
      target: { value },
    } = event;

    if (Name.includes("locationId")) {
      fieldName(`trayId`, "");
      setValue({});
      setInputValue({});
      values.steelpertrays.forEach((value: any, index: number) => {
        Object.keys(value).forEach((v) => {
          fieldName(`steelpertrays[${index}].${v}`, "");
        });
      });
      dispatch(clearTrayData());
      dispatch(clearSteelData());
      dispatch(
        getTrayTypesListing({
          page: "1",
          pageSize: "1000",
          location: value,
        })
      );
      dispatch(
        getSteelList({
          page: 1,
          pageSize: 1000,
          location: value,
        })
      );
    }

    if (Name.includes("code")) {
      // empty other fields on changing code
      fieldName(`steelpertrays[${index}].steelId`, "");
      fieldName(`steelpertrays[${index}].length`, "");
      fieldName(`steelpertrays[${index}].width`, "");
      fieldName(`steelpertrays[${index}].quantity`, "");

      const resp = steelListData?.rows?.filter(
        (steel: any) => steel.code === value
      );

      value = resp[0]?.id;
      fetchSteel(value, index);
    }

    if (Name) fieldName(Name, value);
  };

  const fetchSteel = (value: any, index: number) => {
    const resp = steelListData?.rows?.filter(
      (steel: any) => steel.id === value
    );
    const tempKey = `index-${index}`;
    setProduct({
      ...product,
      [tempKey]: resp,
    });
  };

  const handleCallBack = () => {
    refreshSteelPerTray();
    handleClose();
  };

  React.useEffect(() => {
    dispatch(
      getTrayTypesListing({
        page: "1",
        pageSize: "1000",
        location: editData?.locationId || userInfo?.ownLocation?.locationId,
      })
    );
    dispatch(
      getSteelList({
        page: 1,
        pageSize: 1000,
        location: editData?.locationId || userInfo?.ownLocation?.locationId,
      })
    );
  }, []);

  React.useEffect(() => {
    if (editData?.trayId) {
      const editSteel = editData.steelpertrays.reduce(
        (acc: any, steel: any, index: number) => {
          const response = steelListData?.rows?.filter(
            (steelData: any) => steelData._id === steel.steelId
          );
          const tempKey = `index-${index}`;
          acc[tempKey] = response;
          return acc;
        },
        {}
      );
      setProduct(editSteel);
      const editCode = editData.steelpertrays.reduce(
        (acc: any, steel: any, index: number) => {
          const tempKey = `index-${index}`;
          acc[tempKey] = steel.code;
          return acc;
        },
        {}
      );
      setValue(editCode);
      setInputValue(editCode);
    }
  }, [editData, steelListData]);

  const initialSteelPerTrayData = {
    trayId: "",
    configName: "",
    effectiveStartDate: null,
    effectiveEndDate: null,
    locationId:
      userInfo?.jobRole?.key === "planning_manager" ||
      userInfo?.jobRole?.key === "location_admin"
        ? userInfo?.ownLocation?.locationId
        : "",
    steelpertrays: [
      {
        steelId: "",
        code: "",
        length: "",
        width: "",
        quantity: "",
      },
    ],
  };

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5" align="left">
                    Asset type
                  </Typography>
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "5vh", width: "50%" }}
                    animation={"wave"}
                  />
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle1" align="left">
                    Add steel and quantity for each asset:
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Formik
              initialValues={
                editData?.trayId
                  ? editData
                    ? editData
                    : initialSteelPerTrayData
                  : initialSteelPerTrayData
              }
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                const temp = values.steelpertrays.map((steel: any) => ({
                  steelId: steel.steelId,
                  quantity: steel.quantity,
                }));

                const tempObject = {
                  trayId: values?.trayId,
                  locationId: values?.locationId,
                  name: values?.configName,
                  ...(values?.effectiveStartDate && {
                    effectiveStartDate: values?.effectiveStartDate
                      ?.split("-")
                      ?.reverse()
                      ?.join("-"),
                  }),
                  ...(values?.effectiveEndDate && {
                    effectiveEndDate: values?.effectiveEndDate
                      ?.split("-")
                      ?.reverse()
                      ?.join("-"),
                  }),
                  steelpertrays: temp,
                };

                const data = {
                  data: tempObject,
                  callback: handleCallBack,
                };
                if (editData?.trayId) {
                  const updateData = {
                    data: {
                      ...tempObject,
                      trayId: editData?.trayId,
                      configId: editData?._id,
                    },
                    callback: handleCallBack,
                  };
                  dispatch(editSteelPerTray(updateData));
                } else {
                  dispatch(addSteelPerTray(data));
                }
              }}
            >
              {({ values, resetForm, isSubmitting, errors, setFieldValue }) => (
                <>
                  <Box
                    ml={0}
                    sx={{
                      p: 3,
                      backgroundColor: "#fff ",
                    }}
                  >
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="center">
                            Configuration Name
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <Field
                            component={TextField}
                            type="text"
                            value={values?.configName}
                            label="Configuration Name"
                            variant="outlined"
                            fullWidth
                            required
                            size="small"
                            // disabled={editData?.configName}
                            onChange={(event: any) =>
                              handleChange(
                                event,
                                setFieldValue,
                                `configName`,
                                undefined
                              )
                            }
                          ></Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={0.5}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="center">
                            Location
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <Field
                            component={TextField}
                            type="text"
                            value={values?.locationId}
                            label="Location"
                            select
                            variant="outlined"
                            fullWidth
                            required
                            size="small"
                            disabled={
                              editData?.trayId ||
                              userInfo?.jobRole?.key === "planning_manager" ||
                              userInfo?.jobRole?.key === "location_admin"
                            }
                            onChange={(event: any) =>
                              handleChange(
                                event,
                                setFieldValue,
                                `locationId`,
                                undefined,
                                values
                              )
                            }
                          >
                            {filterData?.allowedLocations?.map(
                              (option: any, i: number) => (
                                // @ts-ignore
                                <MenuItem key={option?._id} value={option?._id}>
                                  {titleCase(option?.fullName)}
                                </MenuItem>
                              )
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={0.5}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="center">
                            Asset Name
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <Field
                            component={TextField}
                            type="text"
                            value={values?.trayId}
                            label="Asset Name"
                            select
                            variant="outlined"
                            fullWidth
                            required
                            size="small"
                            disabled={editData?.trayId || !values?.locationId}
                            onChange={(event: any) =>
                              handleChange(
                                event,
                                setFieldValue,
                                `trayId`,
                                undefined
                              )
                            }
                          >
                            {values?.locationId &&
                              trays?.map((option: any) => (
                                <MenuItem key={option._id} value={option._id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={0.5}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="center">
                            Effective Start Date
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              label="Effective Start Date"
                              format="dd/MM/yyyy"
                              onChange={(event) =>
                                handleOnDateChange(
                                  event,
                                  setFieldValue,
                                  `effectiveStartDate`
                                )
                              }
                              value={
                                values?.effectiveStartDate
                                  ? DateTime.fromJSDate(
                                      new Date(values?.effectiveStartDate)
                                    )
                                  : null
                              }
                              maxDate={
                                values?.effectiveEndDate &&
                                DateTime.fromJSDate(
                                  new Date(values?.effectiveEndDate)
                                )
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                  id: "effectiveStartDate",
                                  name: "effectiveStartDate",
                                  // required: true,
                                },
                                actionBar: {
                                  actions: ["clear"],
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={0.5}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="center">
                            Effective End Date
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              label="Effective End Date"
                              format="dd/MM/yyyy"
                              onChange={(event) =>
                                handleOnDateChange(
                                  event,
                                  setFieldValue,
                                  `effectiveEndDate`
                                )
                              }
                              value={
                                values?.effectiveEndDate
                                  ? DateTime.fromJSDate(
                                      new Date(values?.effectiveEndDate)
                                    )
                                  : null
                              }
                              minDate={DateTime.fromJSDate(
                                new Date(values?.effectiveStartDate)
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                  id: "effectiveEndDate",
                                  name: "effectiveEndDate",
                                  // required: true,
                                },
                                actionBar: {
                                  actions: ["clear"],
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "30px",
                          marginLeft: "0",
                        }}
                      >
                        <Typography variant="subtitle1" align="left">
                          Add steel and quantity for each asset:
                        </Typography>
                      </Grid>
                      <Grid>
                        <FieldArray name="steelpertrays">
                          {({ push, remove }) => (
                            <React.Fragment>
                              {values?.steelpertrays?.map(
                                (_: any, index: any) => (
                                  <div className="add-more-container-a">
                                    <Grid
                                      container
                                      item
                                      key={index}
                                      spacing={2}
                                    >
                                      <Grid item xs={2} sm={2} md={2}>
                                        <VirtualizeCode
                                          options={
                                            values?.locationId
                                              ? steelListData?.rows?.map(
                                                  (s: any) => s.code
                                                ) ?? []
                                              : []
                                          }
                                          originalValue={value}
                                          value={value[`index-${index}`] ?? ""}
                                          setValue={setValue}
                                          originalInputValue={inputValue}
                                          inputValue={
                                            inputValue[`index-${index}`] ?? ""
                                          }
                                          disabled={!values?.locationId}
                                          setInputValue={setInputValue}
                                          index={index}
                                          setFieldValue={setFieldValue}
                                          handleChange={handleChange}
                                        />
                                      </Grid>
                                      <Grid item xs={3.5} sm={3.5} md={3.5}>
                                        <Field
                                          component={TextField}
                                          select
                                          type="text"
                                          value={
                                            values?.steelpertrays[index]
                                              ?.steelId
                                          }
                                          label="Product Name"
                                          required
                                          disabled={!values?.locationId}
                                          placeholder="Product Name"
                                          className="custom-select"
                                          isMulti={true}
                                          size="small"
                                          fullWidth
                                          onChange={(event: any) =>
                                            handleChange(
                                              event,
                                              setFieldValue,
                                              `steelpertrays[${index}].steelId`,
                                              index
                                            )
                                          }
                                        >
                                          {Object.keys(product).length ? (
                                            // @ts-ignore
                                            product[`index-${index}`]?.map(
                                              (option: any) => {
                                                return (
                                                  <MenuItem
                                                    key={option?.id}
                                                    value={option?.id}
                                                  >
                                                    {option?.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                          ) : (
                                            <MenuItem key={"random"} value={""}>
                                              {""}
                                            </MenuItem>
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={1.5} sm={1.5} md={1.5}>
                                        <Field
                                          component={TextField}
                                          select
                                          type="text"
                                          value={
                                            values?.steelpertrays[index]?.length
                                          }
                                          placeholder="Length"
                                          required
                                          label="Length"
                                          className="custom-select"
                                          isMulti={true}
                                          disabled={!values?.locationId}
                                          size="small"
                                          fullWidth
                                          onChange={(event: any) =>
                                            handleChange(
                                              event,
                                              setFieldValue,
                                              `steelpertrays[${index}].length`,
                                              index
                                            )
                                          }
                                        >
                                          {Object.keys(product).length ? (
                                            // @ts-ignore
                                            product[`index-${index}`]?.map(
                                              (option: any) => {
                                                return (
                                                  <MenuItem
                                                    key={option?.id}
                                                    value={option?.length}
                                                  >
                                                    {option?.length}
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                          ) : (
                                            <MenuItem key={"random"} value={""}>
                                              {""}
                                            </MenuItem>
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          className="error-class-message"
                                          component="div"
                                          name={`steelpertrays[${index}].length`}
                                        />
                                      </Grid>
                                      <Grid item xs={1.5} sm={1.5} md={1.5}>
                                        <Field
                                          component={TextField}
                                          select
                                          type="text"
                                          value={
                                            values?.steelpertrays[index]?.width
                                          }
                                          placeholder="Width"
                                          required
                                          disabled={!values?.locationId}
                                          label="Width"
                                          className="custom-select"
                                          isMulti={true}
                                          size="small"
                                          fullWidth
                                          onChange={(event: any) =>
                                            handleChange(
                                              event,
                                              setFieldValue,
                                              `steelpertrays[${index}].width`,
                                              index
                                            )
                                          }
                                        >
                                          {Object.keys(product).length ? (
                                            // @ts-ignore
                                            product[`index-${index}`]?.map(
                                              (option: any) => {
                                                return (
                                                  <MenuItem
                                                    key={option?.id}
                                                    value={option?.width}
                                                  >
                                                    {option?.width}
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                          ) : (
                                            <MenuItem key={"random"} value={""}>
                                              {""}
                                            </MenuItem>
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          className="error-class-message"
                                          component="div"
                                          name={`steelpertrays[${index}].width`}
                                        />
                                      </Grid>
                                      <Grid item xs={1.5} sm={1.5} md={1.5}>
                                        <Field
                                          fullWidth
                                          size="small"
                                          id={`steelpertrays[${index}].quantity`}
                                          name={`steelpertrays[${index}].quantity`}
                                          value={
                                            values.steelpertrays[index].quantity
                                          }
                                          component={TextField}
                                          type="text"
                                          required
                                          disabled={!values?.locationId}
                                          label="Quantity"
                                          onChange={(event: any) =>
                                            handleChange(
                                              event,
                                              setFieldValue,
                                              `steelpertrays[${index}].quantity`,
                                              index
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={2} sm={2} md={2}>
                                        <div className="remove-add-more-item">
                                          {values?.steelpertrays.length > 1 ? (
                                            <IconButton
                                              onClick={() => {
                                                remove(index);
                                                deleteAndShift(product, index);
                                                const temp = deleteAndShift(
                                                  value,
                                                  index
                                                );
                                                setValue({
                                                  ...temp,
                                                });
                                                const temp1 = deleteAndShift(
                                                  inputValue,
                                                  index
                                                );
                                                setInputValue({
                                                  ...temp1,
                                                });
                                              }}
                                            >
                                              <RemoveCircleIcon />
                                            </IconButton>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                )
                              )}

                              <Grid item>
                                {typeof errors.steelpertrays === "string" ? (
                                  <Typography color="error">
                                    {errors.steelpertrays}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <IconButton
                                  disabled={isSubmitting}
                                  onClick={() => push(emptySteelObject)}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </div>
                            </React.Fragment>
                          )}
                        </FieldArray>
                      </Grid>
                      <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {"Save"}
                        </Button>
                        <Button onClick={handleClose} variant="outlined">
                          Cancel
                        </Button>
                        {editData?.trayId ? (
                          <></>
                        ) : (
                          <Button
                            variant="outlined"
                            type="reset"
                            color="error"
                            onClick={(e) => {
                              setProduct({});
                              setValue({});
                              setInputValue({});
                              resetForm();
                            }}
                          >
                            Reset
                          </Button>
                        )}
                      </Box>
                    </Form>
                  </Box>
                </>
              )}
            </Formik>
          </>
        )}
      </>
    </React.Fragment>
  );
};
