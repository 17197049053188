import {
  GET_STEELSHORTAGE_REPORTS,
  GET_DROPDOWN_STEEL_SHORTAGE_REPORT,
} from "../../ActionConstants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
} from "../../Api.service";
import { toggleLoader, toggleSnacker } from "../../../domian/app/AppSlice";
import { generateCodeUrl } from "../../../utils/generateCodeUrl";
import { reformatDates } from "../../../utils/reformatDates";

export const getSteelShortageReportsListing = createAsyncThunk(
  GET_STEELSHORTAGE_REPORTS,
  async (payload: any, thunkApi) => {
    let {
      page,
      pageSize,
      name,
      code,
      location,
      probability,
      startDate,
      endDate,
      shortageRange,
    } = payload;

    startDate = reformatDates(startDate);
    endDate = reformatDates(endDate);

    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const codeUrl = generateCodeUrl(code);
      const url = `steelShortage?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${name ? `name=${name}&` : ""}${codeUrl}${
        startDate ? `startDate=${startDate}&` : ""
      }${endDate ? `endDate=${endDate}&` : ""}${
        shortageRange ? `shortageRange=${shortageRange}&` : ""
      }${location !== "all" ? `locationId=${location}&` : ""}${
        probability ? `probability=${probability}&` : ""
      }`;

      dispatch(toggleLoader(openLoaderPayload));
      const { data } = await API.get(url, { signal });

      const finaleObj = {
        totalCount: data?.data?.totalCount,
        shortageArray: data?.data?.shortageArray.map((item: any) => {
          const { _id, ...rest } = item;
          return {
            id: _id,
            ...rest,
            // name: `${item.name}, ${item.length}mm, ${item.width}mm`,
            name: item.name,
          };
        }),
      };

      return finaleObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Fetching the Reports ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getSteelShortageReportsFilterData = createAsyncThunk(
  GET_DROPDOWN_STEEL_SHORTAGE_REPORT,
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steel/dropdown`;
      const response = await API.post(url, postData, { signal });
      const data = {
        ...response?.data?.data,
        location: response?.data?.data?.allowedLocations,
      };
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Steel Shortage Reports Filter Data ...!"
          )
        )
      );
      return {};
    } finally {
    }
  }
);
