import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateMasterLocation } from "../../domian/app/AppSlice";
import { RootState } from "../../store/store";
import titleCase from "../../utils/titleCase";

export const LocationSelector = () => {
  const dispatch = useDispatch();
  const { userInfo, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <InputLabel id="demo-simple-select-label">Location</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={masterLocation?.locationId}
          size="small"
          sx={{
            maxWidth: "150px",
          }}
          label="Location"
          onChange={(event, child: any) => {
            const temp = {
              name: child?.props?.children.toUpperCase(),
              locationId: event.target.value,
            };
            dispatch(updateMasterLocation(temp));
          }}
        >
          {userInfo?.allowedLocations?.map((location: any) => (
            <MenuItem value={location._id} key={location._id}>
              {titleCase(location.fullName)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
