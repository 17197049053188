import { PopUpMain } from "../../component/popUp";
import { FixedSizeList as List } from "react-window";
import Grid from "@mui/material/Grid";
import * as React from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, FieldProps } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  addInventory,
  getInventoryListing,
} from "../../api/inventory-api/Inventory.service";
import * as yup from "yup";
import titleCase from "../../utils/titleCase";

export function AddInventory(props: any) {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"lg"}>
        <Box>
          <AddInventoryScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}

export interface StateStruct {
  state: string;
  abbrevation: string;
}

export const AddInventoryScreen = (props: any): JSX.Element => {
  const { handleClose, handleCancel } = props;
  const { userInfo } = useSelector((state: RootState) => state.globalAppState);
  const { inventoryData, filterData } = useSelector(
    (state: RootState) => state.inventoryState
  );

  const [initValue, setInitValue] = React.useState<any>({
    date: dayjs(Date.now()),
    locationId:
      userInfo?.jobRole?.key === "supply_chain_manager" ||
      userInfo?.jobRole?.key === "location_admin"
        ? userInfo?.ownLocation?.locationId
        : "",
    steelUsed: [
      {
        quantity: 0,
      },
    ],
  });

  const dispatch: AppDispatch = useDispatch();

  const formatData = (inventoryData: any[]) => {
    if (!Array.isArray(inventoryData)) {
      return [];
    }

    return inventoryData?.map((row) => ({
      id: row._id,
      productName: row.name,
      code: row.code,
      manufacturer: row.manufacturer,
    }));
  };

  React.useEffect(() => {
    if (
      userInfo?.jobRole?.key === "supply_chain_manager" ||
      userInfo?.jobRole?.key === "location_admin"
    ) {
      dispatch(
        getInventoryListing({
          pageIndex: 1,
          pageSize: 1000,
          location: userInfo?.ownLocation?.locationId,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    setInitValue({
      ...initValue,
      steelUsed: formatData(inventoryData),
    });
  }, [inventoryData]);

  // @ts-ignore
  const Row = ({ data: { values, setFieldValue }, index, style }) => {
    const steel = values.steelUsed[index];
    const style1 = {
      ...style,
      display: "flex",
      paddingTop: "30px",
    };

    return (
      <div key={index} style={style1} data-sc={2}>
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {steel.code}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "250px",
            }}
          >
            {steel.productName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {steel.manufacturer}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Field name={`steelUsed[${index}].quantity`}>
            {({ field, meta }: FieldProps) => (
              <FormControl>
                <TextField
                  label="Quantity"
                  name={`steelUsed[${index}].quantity`}
                  id={`steelUsed[${index}].quantity`}
                  type="number"
                  sx={{ width: 140 }}
                  required={
                    !values?.steelUsed?.some(
                      (steel: any) => steel?.quantity !== ""
                    )
                  }
                  size="small"
                  value={field.value ?? ""}
                  onChange={(event: any) => {
                    handleQuantityChange(
                      event,
                      setFieldValue,
                      `steelUsed[${index}].quantity`,
                      index
                    );
                  }}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
      </div>
    );
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: any,
    Name: any,
    index: any
  ) => {
    const newQuantity = event.target.value;
    fieldName(Name, newQuantity);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: any,
    Name: any
  ) => {
    const { value } = event.target;
    fieldName(Name, value);
    setInitValue({
      ...initValue,
      locationId: value,
    });
    dispatch(
      getInventoryListing({
        pageIndex: 1,
        pageSize: 1000,
        location: value,
      })
    );
  };

  return (
    <React.Fragment>
      <>
        <Formik
          initialValues={initValue}
          enableReinitialize={true}
          validationSchema={yup.object({
            steelUsed: yup.array().of(
              yup.object().shape({
                quantity: yup
                  .number()
                  .min(1, "Qty must be greater than 1")
                  .max(999.99, "Qty is exceeding the limit"),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);

            const filteredArr = values.steelUsed
              .filter((item: any) => item?.quantity && item?.quantity !== "")
              .map(({ id, quantity }: { id: any; quantity: any }) => ({
                steelId: id,
                quantity,
                locationId: values?.locationId,
              }));

            const data = {
              date: dayjs(values?.date).format("DD-MM-YYYY"),
              inventory: filteredArr,
            };

            dispatch(addInventory(data)).then(() => {
              handleCancel();
            });
          }}
        >
          {({ values, resetForm, isSubmitting, setFieldValue }) => (
            <>
              <Box
                ml={0}
                sx={{
                  p: 3,
                  backgroundColor: "#fff ",
                }}
              >
                <Form>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      marginTop: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="left"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Field name="date">
                        {({ field, meta }: FieldProps) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              label="Selected Date"
                              disabled
                              value={field.value}
                              slotProps={{
                                actionBar: {
                                  actions: ["clear"],
                                },
                                textField: {
                                  size: "small",
                                },
                              }}
                              format="DD-MM-YYYY"
                            />
                            {meta.touched && meta.error && (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "2px",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                *{meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </LocalizationProvider>
                    <Typography
                      variant="h5"
                      align="left"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "30px",
                      }}
                    >
                      Location
                    </Typography>
                    <Field name={`locationId`}>
                      {({ field, meta }: FieldProps) => (
                        <FormControl>
                          <TextField
                            label="Location"
                            select
                            name={`locationId`}
                            id={`locationId`}
                            required
                            sx={{ width: "200px" }}
                            size="small"
                            disabled={
                              userInfo?.jobRole?.key ===
                                "supply_chain_manager" ||
                              userInfo?.jobRole?.key === "location_admin"
                            }
                            value={field.value ?? ""}
                            onChange={(event: any) =>
                              handleChange(event, setFieldValue, `locationId`)
                            }
                          >
                            {filterData?.allowedLocations?.map(
                              (option: any, i: number) => (
                                // @ts-ignore
                                <MenuItem key={option?._id} value={option?._id}>
                                  {titleCase(option?.fullName)}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                          <FormHelperText error={meta.touched && !!meta.error}>
                            {meta.touched && meta.error}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sm={3}
                      sx={{
                        paddingLeft: "0px !important",
                      }}
                    >
                      <Typography variant="h5" align="left">
                        Code
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      sm={4}
                      sx={{
                        paddingLeft: "0px !important",
                      }}
                    >
                      <Typography variant="h5" align="left">
                        Product Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sm={3}
                      sx={{
                        paddingLeft: "0px !important",
                      }}
                    >
                      <Typography variant="h5" align="left">
                        Manufacturer
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={2}
                      sm={2}
                      sx={{
                        paddingLeft: "0px !important",
                      }}
                    >
                      <Typography variant="h5" align="left">
                        Quantity
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={2}>
                    <List
                      height={450}
                      overscanCount={20}
                      width={"100%"}
                      itemCount={values?.steelUsed?.length}
                      itemSize={65}
                      itemData={{
                        values: values,
                        setFieldValue: setFieldValue,
                      }}
                    >
                      {Row}
                    </List>
                  </Grid>
                  <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                    <Button
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      type="reset"
                      color="error"
                      onClick={(e) => resetForm()}
                    >
                      Reset
                    </Button>
                  </Box>
                </Form>
              </Box>
            </>
          )}
        </Formik>
      </>
    </React.Fragment>
  );
};
