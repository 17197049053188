import { createSlice } from "@reduxjs/toolkit";
import {
  getInventoryListing,
  getInventoryFilterData,
  getInventoryAllListing,
} from "../../../api/inventory-api/Inventory.service";

interface InvetoryState {
  inventoryData: any;
  inventoryAllData: any;
  filterData: any;
  rowCount: any;
  inventoryAddPageLoading: boolean;
}

const initialState: InvetoryState = {
  inventoryData: {},
  inventoryAllData: {},
  filterData: {},
  rowCount: 0,
  inventoryAddPageLoading: false,
};

const InventorySlice = createSlice({
  name: "Inventory",
  initialState,
  reducers: {
    inventoryAddIsLoading: (state, { payload }) => {
      state.inventoryAddPageLoading = payload;
    },
    clearSteelData: (state) => {
      state.inventoryData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventoryListing.fulfilled, (state, { payload }) => {
      state.inventoryData = payload?.rows;
      state.rowCount = payload?.count;
    });
    builder.addCase(getInventoryAllListing.fulfilled, (state, { payload }) => {
      state.inventoryAllData = payload;
      state.rowCount = payload?.count;
    });
    builder.addCase(getInventoryFilterData.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});

export const InventoryReducer = InventorySlice.reducer;

export const { inventoryAddIsLoading, clearSteelData } = InventorySlice.actions;
