import {
  Button,
  ButtonGroup,
  Card,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Box } from "@mui/system";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { InputSearch } from "../../component/search/InputSearch";
import { QuickSearchToolbarProps } from "../../component/search/types";
import { RootState } from "../../store/store";
import titleCase from "../../utils/titleCase";
import theme from "../../Themes";
import { useState } from "react";
import { checkPermission } from "../../utils/checkPermission";
import { finalUrl } from "../../api/Api.utils";

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView, masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { filterData } = useSelector((state: RootState) => state.steelState);

  const [value, setValue] = useState("");

  const {
    componentName = "",
    searchText = "",
    setSearchText,
    location,
    setLocation,
    setPermission,
  } = props;

  const clearAllFilters = () => {
    setLocation(masterLocation?.locationId);
    setValue("");
    props.onSearchFn("");
  };

  const onDownload = async () => {
    const downloadUrl =
      finalUrl +
      `tray/download-list${
        location !== "all" ? `?locationId=${location}` : ""
      }${
        searchText ? `${location !== "all" ? "&" : "?"}name=${searchText}` : ""
      }`;
    window.location.href = downloadUrl;
  };

  if (filterData?.allowedLocations?.length) {
    return (
      <Box>
        <Box pb={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          {componentName === "Asset" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <InputSearch
                    onSearchFn={props.onSearchFn}
                    componentName={componentName}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    value={value}
                    setValue={setValue}
                  />
                </div>
                <Box className={"resp-select"}>
                  <TextField
                    type="text"
                    name="location"
                    label={`Location`}
                    select
                    variant="outlined"
                    fullWidth
                    value={location}
                    onChange={(e) => {
                      e.target.value !== "All"
                        ? setLocation(e.target.value)
                        : setLocation("");
                      setPermission(() => {
                        const getPermission = checkPermission(
                          e.target.value,
                          userInfo,
                          "ASSET"
                        );
                        return getPermission;
                      });
                    }}
                    size="small"
                    InputProps={{
                      sx: {
                        width: "108px",
                        height: "35px",
                        fontSize: "14px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {filterData?.allowedLocations?.length ? (
                      <MenuItem value={"all"}>All</MenuItem>
                    ) : (
                      "no data"
                    )}
                    {filterData?.allowedLocations?.map(
                      (option: any, i: number) => (
                        <MenuItem key={i} value={option._id}>
                          {titleCase(option?.fullName)}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Box>
                <Box className={"resp-select"}>
                  <Button
                    variant="outlined"
                    onClick={clearAllFilters}
                    sx={{
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                      color: theme.palette.indicator.main,
                    }}
                  >
                    Clear All
                  </Button>
                </Box>
              </div>
              <Box className={"resp-select"} sx={{ float: "right" }}>
                <ButtonGroup aria-label="outlined primary button group">
                  <Tooltip title="Download Report">
                    <Button
                      startIcon={<DownloadIcon />}
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        width: "54px",
                        height: "35px",
                        "& .MuiButton-startIcon": {
                          marginRight: "0",
                          marginLeft: "0",
                        },
                      }}
                      onClick={onDownload}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Box>
            </div>
          )}
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const TrayTypesFilters = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
