export const getMonthsSchedule = (params: any) => {
  let [processingStartDate, processingEndDate] = ["", ""];
  const [currentMonth, currentYear] = params.split("-");

  switch (currentMonth) {
    case "JAN":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-01-01`,
        `${currentYear}-01-31`,
      ];
      break;
    case "FEB":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-02-01`,
        `${currentYear}-02-${leapYear(currentYear) ? "29" : "28"}`,
      ];
      break;
    case "MAR":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-03-01`,
        `${currentYear}-03-31`,
      ];
      break;
    case "APR":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-04-01`,
        `${currentYear}-04-30`,
      ];
      break;
    case "MAY":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-05-01`,
        `${currentYear}-05-31`,
      ];
      break;
    case "JUN":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-06-01`,
        `${currentYear}-06-30`,
      ];
      break;
    case "JUL":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-07-01`,
        `${currentYear}-07-31`,
      ];
      break;
    case "AUG":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-08-01`,
        `${currentYear}-08-31`,
      ];
      break;
    case "SEP":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-09-01`,
        `${currentYear}-09-30`,
      ];
      break;
    case "OCT":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-10-01`,
        `${currentYear}-10-31`,
      ];
      break;
    case "NOV":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-11-01`,
        `${currentYear}-11-30`,
      ];
      break;
    case "DEC":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-12-01`,
        `${currentYear}-12-31`,
      ];
      break;
  }
  return [processingStartDate, processingEndDate];
};

function leapYear(year: any) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
