import * as React from "react";
import { Box, Typography } from "@mui/material";
import { PopUpMain } from "../../component/popUp";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export function ViewConfig(props: any) {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"md"}>
        <Box>
          <ViewConfigScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}

export const ViewConfigScreen = (props: any) => {
  const { configSchedule } = props;

  const columns: GridColDef[] = [
    {
      headerName: "Code",
      field: "code",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Product Name",
      field: "name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Length (mm)",
      field: "length",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Width (mm)",
      field: "width",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <Box
      ml={0}
      sx={{
        p: 3,
        backgroundColor: "#fff ",
      }}
    >
      <Grid container spacing={2.5}>
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            sx={{ marginTop: "10px", textAlign: "center", fontWeight: "600" }}
          >
            Asset Name
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography sx={{ marginTop: "10px", textAlign: "center" }}>
            {configSchedule?.steelpertrayconfig?.tray?.name ?? "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            sx={{ marginTop: "10px", textAlign: "center", fontWeight: "600" }}
          >
            Location
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography sx={{ marginTop: "10px", textAlign: "center" }}>
            {configSchedule?.steelpertrayconfig?.location?.name ?? "-"}
          </Typography>
        </Grid>
        <Typography
          sx={{ marginTop: "50px", textAlign: "center", marginBottom: "25px" }}
        >
          Steel and it's quantity used for this configuration:
        </Typography>
        <div
          style={{
            minHeight: "400px",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          <DataGrid
            columns={columns}
            rows={configSchedule?.bom ?? []}
            getRowId={(row) => row?._id}
            rowsPerPageOptions={[]}
            hideFooter
            sx={{
              padding: "16px",
              borderColor: "#e0e0e07a",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
              "& .MuiDataGrid-cell a": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f6fa",
                borderTop: "2px solid #e0e0e07a",
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600 !important",
              },
              "& .MuiDataGrid-cell:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-cell": {
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaders": {
                border: "0",
              },
              "& .MuiDataGrid-columnHeadersInner": {
                borderBottom: "1px solid #e0e0e07a !important",
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                border: 0,
                background: "white",
              },
            }}
          />
        </div>
      </Grid>
    </Box>
  );
};
