import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { SteelOnOrderRow } from "./SteelOnOrderRow";
import TableRowNoResults from "../steelPerTray/TableNoResults";
import {
  Order,
  getComparator,
  stableSort,
} from "../steelPerTray/SteelPerTrayTable";
import { visuallyHidden } from "@mui/utils";
import React from "react";

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort, tableCells } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {tableCells?.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: "600",
              backgroundColor: "#f5f6fa",
              borderRight: "1px solid #e0e0e07a",
              textAlign: "center",
            }}
          >
            {headCell.label && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                disabled={headCell.id === "action"}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const SteelOnOrderTable = (props: any): JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("purchaseOrderNo");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(props?.steelOnOrderListData, getComparator(order, orderBy)),
    [order, orderBy, props?.steelOnOrderListData]
  );

  const tableCells: any = [
    {
      id: "collapse",
      label: "",
    },
    {
      id: "purchaseOrder",
      label: "PO NUMBER",
    },
    {
      id: "supplier",
      label: "SUPPLIER",
    },

    ...(!props.isPurchaseView
      ? [
          {
            id: "name",
            label: "SHIPMENT NAME",
          },
          {
            id: "shipmentType",
            label: "SHIPMENT TYPE",
          },
          {
            id: "purchaseDate",
            label: "PURCHASE",
          },
          {
            id: "deliveryDate",
            label: "DELIVERY",
          },
        ]
      : [
          {
            id: "purchaseDate",
            label: "PURCHASE",
          },
        ]),
    {
      id: "locationName",
      label: "LOCATION",
    },
    {
      id: "action",
      label: "ACTIONS",
    },
  ];

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "4px",
        border: "1px solid rgba(224, 224, 224, 1)",
        boxShadow: "unset",
        borderColor: "#e0e0e07a",
        borderBottom: "0",
      }}
    >
      <Table aria-label="steel on order data table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          tableCells={tableCells}
          rowCount={props?.steelOnOrderListData?.length}
        />
        <TableBody>
          {visibleRows?.length ? (
            visibleRows?.map((row: any, index: number) => (
              <SteelOnOrderRow
                key={`row-${index}`}
                row={row}
                index={index}
                refreshSteelOnOrder={props.refreshSteelOnOrder}
                onEdit={props.onEdit}
                steelOnOrderFiltersData={props.steelOnOrderFiltersData}
                steelListData={props.steelListData}
                permission={props.permission}
                page={props.page}
                isPurchaseView={props.isPurchaseView}
              />
            ))
          ) : (
            <TableRowNoResults colSpan={tableCells?.length} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
