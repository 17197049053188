import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_USER,
  DELETE_USER,
  GET_ALL_USER,
  GET_DROPDOWN_USER,
  GET_EDIT_USER,
  RESEND_INVITE,
  UPDATE_USER,
} from "../ActionConstants";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";

export const getUserList = createAsyncThunk(
  GET_ALL_USER,
  async (postData: any, thunkApi) => {
    const { names, emailIds, isActive, roles, locations, page, pageSize } =
      postData;
    // //const token = authLogin;
    const { signal, dispatch } = thunkApi;
    try {
      const url = `listUser?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${names ? `name=${names}&` : ""}${
        emailIds ? `emailId=${emailIds}&` : ""
      }${isActive ? `isActive=${isActive}&` : ""}${
        roles ? `roleId=${roles}&` : ""
      }${locations !== "all" ? `locationId=${locations}&` : ""}`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };
      return finelObj;
    } catch (err: any) {
      if (err?.response?.data?.message === "Token is invalid.") {
        dispatch(toggleSnacker(errorOpen("Please login again.")));
      } else {
        dispatch(
          toggleSnacker(
            errorOpen(
              err?.response?.data?.responseMessage
                ? err?.response?.data?.responseMessage
                : "Error While Getting User Data ...!"
            )
          )
        );
      }
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addUser = createAsyncThunk(
  ADD_USER,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("addUser", postData.data, { signal });
      if (response) {
        postData?.callback();
        const data = response?.data;
        if (
          data.status === 0 &&
          data.message === "User already exist with this email id."
        ) {
          dispatch(
            toggleSnacker(
              errorOpen("User already exist with this email address!")
            )
          );
        } else {
          dispatch(
            toggleSnacker(successOpen("User has been added successfully!"))
          );
        }
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while adding user, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditUser = createAsyncThunk(
  GET_EDIT_USER,
  async (id: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(`listUser/?userId=${id}`, { signal });
      if (response) {
        const data = response?.data?.data;
        data.firstName = data.name.split(" ")[0];
        data.lastName = data.name.split(" ")[1];
        data.userId = data._id;
        data.locationId = data.location._id;
        data.roleId = data.role._id;
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while getting users, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const updateUser = createAsyncThunk(
  UPDATE_USER,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const tempData = {
        userId: postData?.data.userId,
        roleId: postData?.data.roleId,
        locationId: postData?.data.locationId,
        firstName: postData?.data.firstName,
        lastName: postData?.data.lastName,
        isActive: postData?.data.isActive,
      };
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch("updateUser", tempData, {
        signal,
      });
      if (response) {
        postData?.callback();
        dispatch(toggleSnacker(successOpen("User edited successfully!")));
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while saving user, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteUser = createAsyncThunk(
  DELETE_USER,
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `deleteUser?userId=${postData?.userId}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response?.data;
      if (data?.status === 0) dispatch(toggleSnacker(errorOpen(data?.message)));
      else {
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "User has been deleted successfully!"
            )
          )
        );
      }
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Deleting User, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterData = createAsyncThunk(
  GET_DROPDOWN_USER,
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, postData, { signal });
      const data = {
        ...response?.data?.data,
        location: response?.data?.data?.allowedLocations,
      };
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting User Data ...!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const resendInvite = createAsyncThunk(
  RESEND_INVITE,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("resendInvite", postData.data, {
        signal,
      });
      if (response?.data?.status === 1)
        dispatch(
          toggleSnacker(successOpen("Email invite has been sent successfully!"))
        );
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while sending invite, please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
