import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
import { AddUser } from "./AddUser";
import {
  deleteUser,
  getEditUser,
  getFilterData,
  getUserList,
  resendInvite,
} from "../../api/admin/Api.service";
import { clearEditData } from "./slice/AdminSlice";
import { AdminFilters } from "./AdminFilters";
import { checkPermission } from "../../utils/checkPermission";
import titleCase from "../../utils/titleCase";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export const AdminScreenMain = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditSteelData({ id: "" });
    dispatch(clearEditData());
  };

  const { userListData } = useSelector((state: any) => state.adminState);
  const { isMobileView, masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [editSteelData, setEditSteelData] = useState({ id: "" });
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [steelData, setSteelData] = useState({
    names: "",
    emailIds: "",
    isActive: "",
    roles: "",
    locations: masterLocation.locationId,
  });

  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo,
      "USER"
    );
    return getPermission;
  });

  useEffect(() => {
    setSteelData({
      ...steelData,
      locations: masterLocation.locationId,
    });
  }, [masterLocation]);

  useEffect(() => {
    refreshSteel();
  }, [pageState, steelData]);

  useEffect(() => {
    if (steelData?.locations && steelData?.locations !== "all")
      dispatch(getFilterData({ locationId: steelData?.locations }));
    else dispatch(getFilterData({}));
  }, [userListData]);

  useEffect(() => {
    setPermission(() => {
      const getPermission = checkPermission(
        masterLocation?.locationId,
        userInfo,
        "USER"
      );
      return getPermission;
    });
  }, [masterLocation]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const refreshSteel = () => {
    const { names, emailIds, isActive, roles, locations } = steelData;
    const searchData = {
      page: pageState.page,
      pageSize: pageState.pageSize,
      names: names,
      emailIds: emailIds,
      isActive: isActive,
      roles: roles,
      locations: locations,
    };
    dispatch(getUserList(searchData));
    if (steelData?.locations && steelData?.locations !== "all")
      dispatch(getFilterData({ locationId: steelData?.locations }));
    else dispatch(getFilterData({}));
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const t = params.value;
        return <span>{titleCase(t)}</span>;
      },
    },
    {
      field: "emailId",
      headerName: "EMAIL ADDRESS",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "ROLE",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const t = params.value;
        return <span>{titleCase(t)}</span>;
      },
    },
    {
      field: "location",
      headerName: "LOCATION",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const t = params.value;
        return <span>{titleCase(t)}</span>;
      },
    },
    {
      field: "isActive",
      headerName: "STATUS",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const emailConfimationStatus = params.row.emailConfimationStatus;
        const status = params.value;
        if (!status && !emailConfimationStatus)
          return <span>Pending Invite</span>;
        else if (status && emailConfimationStatus) return <span>Active</span>;
        return <span>Inactive</span>;
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const { status, is_form_filled } = params.row;
        const onClick = (e: any) => {
          const currentRow = params.row;
          setDeletId(currentRow._id);
          handleOpen();
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;
          setEditSteelData(currentRow);
          dispatch(getEditUser(currentRow._id)).then((response) => {
            setAddOpen(true);
          });
        };

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            {params.row.emailConfimationStatus ? (
              <Tooltip title="Edit">
                <IconButton disabled={!permission}>
                  <EditOutlinedIcon
                    color={permission ? "error" : "disabled"}
                    onClick={onEdit}
                  ></EditOutlinedIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Resend Invite">
                <IconButton disabled={!permission}>
                  <ForwardToInboxOutlinedIcon
                    color={permission ? "error" : "disabled"}
                    onClick={() => {
                      dispatch(
                        resendInvite({
                          data: {
                            userId: params.row.id,
                            locationId: userInfo?.ownLocation?.locationId,
                          },
                        })
                      );
                    }}
                  ></ForwardToInboxOutlinedIcon>
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Delete">
              <IconButton disabled={!permission}>
                <DeleteForeverOutlinedIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onClick}
                ></DeleteForeverOutlinedIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="User Management"
            addBtnTitle="Add User"
            disabled={!permission}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          <DataGrid
            sx={{
              padding: "16px",
              borderColor: "#e0e0e07a",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
              "& .MuiDataGrid-cell a": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f6fa",
                borderTop: "2px solid #e0e0e07a",
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600 !important",
              },
              "& .MuiDataGrid-cell:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-cell": {
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaders": {
                border: "0",
              },
              "& .MuiDataGrid-columnHeadersInner": {
                borderBottom: "1px solid #e0e0e07a !important",
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                border: 0,
                background: "white",
              },
            }}
            rows={userListData?.rows?.length > 0 ? userListData?.rows : []}
            getRowId={(row) => `${row.emailId}-${row.location}`}
            columns={columns}
            density={"standard"}
            disableColumnMenu={true}
            components={{
              LoadingOverlay: SkeletonLoadingOverlay,
              Toolbar: AdminFilters,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Records Available
                </Stack>
              ),
            }}
            disableSelectionOnClick={true}
            rowCount={userListData?.total}
            rowsPerPageOptions={[]}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            componentsProps={{
              toolbar: {
                steelData: steelData,
                setSteelData: setSteelData,
                setPermission: setPermission,
              },
            }}
          />
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete this user?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteUser({
                      userId: deletId,
                      callback: refreshSteel,
                      locationId: userInfo?.ownLocation?.locationId,
                    })
                  ).then(() => {
                    handleClose();
                  });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddUser
        open={addOpen}
        handleClose={handleAddClose}
        editData={editSteelData}
        title={editSteelData?.id ? "Edit User" : "Add User"}
        refreshSteel={refreshSteel}
      />
    </Box>
  );
};
