export const checkPermission = (
  filterLocation: any,
  userInfo: any,
  page: any
) => {
  const isSuperUser = userInfo.jobRole.key === "super_user";
  const permissions = Object.values(userInfo.permissions[page]).every(Boolean);
  return (
    permissions &&
    (isSuperUser || filterLocation === userInfo.ownLocation?.locationId)
  );
};
