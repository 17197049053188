import {
  GET_TRAY_TYPES,
  ADD_TRAY_TYPES,
  EDIT_TRAY_TYPES,
  GET_EDIT_TRAY_DATA,
  DELETE_TRAY_TYPE,
} from "../ActionConstants";

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import { EditTrayTpePayload, AddTrayTypePayload } from "../../pages/trayTypes";

export const getTrayTypesListing = createAsyncThunk(
  GET_TRAY_TYPES,
  async (payload: any, thunkApi) => {
    //const token = authLogin
    const { page, pageSize, location, name } = payload;
    const { signal, dispatch } = thunkApi;
    try {
      const url = `tray?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${location !== "all" ? `locationId=${location}&` : ""}${
        name ? `name=${name}&` : ""
      }`;
      if (payload?.pageSize !== "1000")
        dispatch(toggleLoader(openLoaderPayload));
      const { data } = await API.get(url, { signal });
      data.data.rows = data?.data.rows.map((t: any) => ({
        ...t,
        location: t.location.name,
        fullName: t.location.fullName,
      }));
      return data?.data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Asset Data ...!"
          )
        )
      );
      if (payload?.pageSize !== "1000")
        dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      if (payload?.pageSize !== "1000")
        dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const addTrayTypes = createAsyncThunk(
  ADD_TRAY_TYPES,
  async (postData: AddTrayTypePayload, thunkApi) => {
    //const token = authLogin
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`tray`, postData.data, {
        signal,
      });
      if (response) {
        postData?.callback();
        if (response?.data?.status === 0)
          dispatch(toggleSnacker(errorOpen(response?.data?.message)));
        else {
          dispatch(toggleSnacker(successOpen("Asset Added Successfully!")));
          return response?.data;
        }
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Adding Asset Data, Please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const editTrayTypes = createAsyncThunk(
  EDIT_TRAY_TYPES,
  async (postData: EditTrayTpePayload, thunkApi) => {
    //const token = authLogin
    const { rejectWithValue, signal, dispatch } = thunkApi;

    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch(`tray`, postData.data, { signal });

      if (response) {
        postData?.callback();
        if (response?.data?.status === 0)
          dispatch(toggleSnacker(errorOpen(response?.data?.message)));
        else {
          dispatch(toggleSnacker(successOpen("Asset Edited Successfully!")));
          return response?.data;
        }
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Editing Asset Data , Please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getEditTrayData = createAsyncThunk(
  GET_EDIT_TRAY_DATA,
  async (id: any, thunkApi) => {
    //const token = authLogin
    const { signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(`tray?trayId=${id}`, { signal });
      if (response) {
        const resp = response?.data?.data;
        const temp = {
          _id: resp._id,
          name: resp.name,
          locationId: resp.location._id,
        };
        return temp;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Asset, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const deleteTrayTypes = createAsyncThunk(
  DELETE_TRAY_TYPE,
  async (postData: any, thunkApi) => {
    //const token = authLogin
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.delete(
        `tray?trayId=${postData?.trayId}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      if (response?.data?.status === 0)
        dispatch(toggleSnacker(errorOpen(response?.data?.message)));
      else {
        if (response) {
          dispatch(toggleSnacker(successOpen("Asset Deleted Successfully!")));
          return response?.data;
        }
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Deleting Asset Data"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
