import { createSlice } from "@reduxjs/toolkit";
import {
  getEditTrayData,
  getTrayTypesListing,
} from "../../../api/tray-types-api/TrayType.service";

const initialState = {
  trays: [],
  totalRows: 0,
  traysEditData: {},
} as any;

const TrayTypesSlice = createSlice({
  name: "TrayType",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.traysEditData = {};
    },
    clearTrayData: (state) => {
      state.trays = [];
      state.totalRows = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getTrayTypesListing.fulfilled,
      (state, { payload }: any) => {
        state.trays = payload?.rows;
        state.totalRows = payload?.total;
      }
    );
    builder.addCase(getEditTrayData.fulfilled, (state, { payload }: any) => {
      state.traysEditData = payload;
    });
  },
});

export const TrayTypeReducer = TrayTypesSlice.reducer;
export const { clearEditData, clearTrayData } = TrayTypesSlice.actions;
