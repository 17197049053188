import { PopUpMain } from "../../component/popUp";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, MenuItem, Skeleton, Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import { intialTrayData } from "./data";
import { AppDispatch, RootState } from "../../store/store";
import {
  addTrayTypes,
  editTrayTypes,
} from "../../api/tray-types-api/TrayType.service";
import titleCase from "../../utils/titleCase";

export function AddTrayType(props: any) {
  const { title = "Add Asset", open } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"sm"}>
        <Box>
          <AddTrayTypeScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}

export interface StateStruct {
  state: string;
  abbrevation: string;
}
export const AddTrayTypeScreen = (props: any): JSX.Element => {
  const { handleClose, editData, triggerNavigateNormal } = props;
  const dispatch: AppDispatch = useDispatch();
  const { traysEditData } = useSelector((state: any) => state.trayTypeState);
  const { userInfo } = useSelector((state: RootState) => state.globalAppState);
  const { filterData } = useSelector((state: RootState) => state.steelState);

  const validationSchema = yup.object({
    name: yup.string().required("Asset Name Is Required"),
    locationId: yup.string().required("Location Is Required"),
  });

  const intialTrayData = {
    id: "",
    name: "",
    type: "",
    steelGrade: "",
    thickness: "",
    width: "",
    length: "",
    code: "",
    locationId:
      userInfo?.jobRole?.key === "planning_manager" ||
      userInfo?.jobRole?.key === "location_admin"
        ? userInfo?.ownLocation?.locationId
        : "",
  };

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                {[...Array(11)].map(() => (
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                  </Grid>
                ))}
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <Formik
            initialValues={
              editData?._id
                ? traysEditData
                  ? traysEditData
                  : intialTrayData
                : intialTrayData
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              const postData = {
                data: values,
                callback: triggerNavigateNormal,
              };

              if (editData?._id) {
                const editPostData = {
                  data: {
                    ...values,
                    trayId: editData?._id,
                    code:
                      traysEditData?.code == null
                        ? "A-158"
                        : traysEditData?.code,
                  },
                  callback: triggerNavigateNormal,
                };
                dispatch(editTrayTypes(editPostData));
              } else {
                dispatch(addTrayTypes(postData));
              }
            }}
          >
            {({ resetForm, isSubmitting }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                  }}
                >
                  <Form>
                    <Grid container spacing={2.5}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          size="small"
                          label="Asset Name*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          select
                          id="locationId"
                          name="locationId"
                          type="text"
                          size="small"
                          label="Location"
                          variant="outlined"
                          fullWidth
                          disabled={
                            editData?._id ||
                            userInfo?.jobRole?.key === "planning_manager" ||
                            userInfo?.jobRole?.key === "location_admin"
                          }
                        >
                          {filterData?.allowedLocations?.map(
                            (option: any, i: number) => (
                              // @ts-ignore
                              <MenuItem key={option?._id} value={option?._id}>
                                {titleCase(option?.fullName)}
                              </MenuItem>
                            )
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {"Save"}
                      </Button>
                      <Button onClick={handleClose} variant="outlined">
                        Cancel
                      </Button>
                      {editData?._id ? (
                        <></>
                      ) : (
                        <Button
                          variant="outlined"
                          type="reset"
                          color="error"
                          onClick={(e) => resetForm()}
                        >
                          Reset
                        </Button>
                      )}
                    </Box>
                  </Form>
                </Box>
              </>
            )}
          </Formik>
        )}
      </>
    </React.Fragment>
  );
};
